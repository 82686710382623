.artifact-card {
  position: absolute;
  top: 0px;
  left: 40px;
  width: calc(95% - 40px);
  height: 95%;
  max-width: 400px;
  max-height: 500px;
  background-color: rgba(255, 255, 255, 0.9);
  overflow-y: auto;
  border-radius: 4px;
  border-width: initial;
  border-style: none;
  border-color: initial;
  border-image: initial;
  margin: 10px;
}

.fullscreen-card {
  max-width: unset;
  max-height: unset;
}

.artifact-close-button {
  font-size: 35px;
  position: absolute;
  right: 0;
  z-index: 5;
}

.artifact-close-button:hover {
  color: rgba(200, 100, 0);
}

.artifact-fullscreen-button {
  font-size: 35px;
  position: absolute;
  top: 35px;
  right: 0;
  z-index: 5;
}

.artifact-fullscreen-button:hover {
  color: rgba(200, 100, 0);
}

.artifact-viewer > h3 {
  text-align: center;
  padding: 20px;
}

.artifact-info {
  padding: 0px 20px 20px 20px;
}
