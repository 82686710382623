.exhibition-tutorial {
  position: absolute;
  left: 3.4em;
  top: 0.5em;
  background-color: #fff;
  max-width: 70vw;
  width: 400px;
  min-width: 250px;
  border-radius: 2%;
  overflow-y: auto;
  max-height: 67.5vh;
}

.exhibition-tutorial .paragraph {
  padding: 1em 1em 0 1em;
  margin-bottom: 0;
  -webkit-user-select: none; /* Safari */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* IE10+/Edge */
  user-select: none; /* Standard */
}

.exhibition-tutorial .controls {
  padding: 0 1em 1em 1em;
}

.exhibition-tutorial .controls .ui.buttons {
  width: 100%;
}

.exhibition-tutorial .controls .ui.buttons .button {
  width: 50%;
}

.exhibition-tutorial .section {
  border-bottom: 1px solid #ddd;
  margin-bottom: 1em;
  padding-bottom: 1em;
  width: 100%;
  -webkit-user-select: none; /* Safari */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* IE10+/Edge */
  user-select: none; /* Standard */
}

.exhibition-tutorial .section:last-child {
  border-bottom: 0;
}

.exhibition-tutorial h2 {
  padding: 0.5em;
  border-top-right-radius: 4px;
  border-top-left-radius: 4px;
  margin-bottom: 0;
  -webkit-user-select: none; /* Safari */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* IE10+/Edge */
  user-select: none; /* Standard */
}

.exhibition-tutorial h2 .ui.button.close-button {
  position: absolute;
  top: 1em;
  right: 0.5em;
  padding: 0.5em;
  -webkit-user-select: none; /* Safari */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* IE10+/Edge */
  user-select: none; /* Standard */
}

.exhibition-tutorial .disable-control {
  line-height: 1.5em;
  margin-top: 0.5em;
}

.exhibition-tutorial .disable-control * {
  vertical-align: middle;
  margin-right: 0.5em;
}

.exhibition-tutorial .paragraph .section .text {
  font-size: 1.2em;
  display: inline-block;
  width: calc(80% - 0.5em);
  vertical-align: top;
  -webkit-user-select: none; /* Safari */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* IE10+/Edge */
  user-select: none; /* Standard */
}

.exhibition-tutorial .paragraph .section .image {
  height: 10%;
  width: 10%;
  min-width: 30px;
  min-height: 30px;
  margin-left: 0.5em;
  display: inline-block;
}

.exhibition-tutorial .paragraph .section:after {
  content: '';
  display: block;
  clear: both;
}

.exhibition-tutorial .paragraph .section.noicon .text,
.exhibition-tutorial .paragraph .section.noicon .text,
.exhibition-tutorial .paragraph .section.keyboard .text,
.exhibition-tutorial .paragraph .section.tour .text {
  width: 100%;
  -webkit-user-select: none; /* Safari */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* IE10+/Edge */
  user-select: none; /* Standard */
}

.exhibition-tutorial .paragraph .section.keyboard .image,
.exhibition-tutorial .paragraph .section.tour .image {
  width: 40%;
  display: block;
  margin: auto;
}

.exhibition-tutorial .paragraph .section .image {
  width: 10%;
}

.exhibition-tutorial .paragraph .section .text {
  -webkit-user-select: none; /* Safari */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* IE10+/Edge */
  user-select: none; /* Standard */
}

.exhibition-tutorial .paragraph .section.noicon .text {
  width: 100%;
}
