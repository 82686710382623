.article div.required.ui.form.field.section {
  font-size: 2em;
  line-height: 1.2857em;
}

.article div.required.ui.field.section {
  font-size: 2em;
  line-height: 1.2857em;
}

.article .ui.form input[type='text'],
.article .ui.input input,
.article-categories .ui.input input {
  padding: 0 0.5em;
}

.ui.breadcrumb button.button {
  vertical-align: text-top;
  margin-bottom: 0.25em;
}

.ui.breadcrumb button.button:first-child {
  margin-left: 1em;
}

.article .ui.form select {
  font-weight: 600;
  padding: 0px;
}

.article div.ui.section {
  vertical-align: bottom;
}

.article div.ui.segment.vertical.center.aligned {
  border-bottom: none;
}

.article .preview-wrapper {
  text-align: center;
}

.article .image-preview-container,
.article .ui.image,
.article .ui.image > a > img {
  max-height: 20em;
  max-width: 100%;
  display: inline;
  margin: 1px;
}

.article-categories .ui.list > .item {
  font-size: normal;
  list-style-type: circle;
  list-style-position: inside;
}

.article-categories .ui.list > .item > .field {
  display: inline;
}
.article-categories .ui.list > .item > .field > .action {
  max-width: 80%;
}

.article-categories .ui.list > .item > .field input {
  min-width: 128px;
}

.article button.bare.icon,
.article-categories button.bare.icon {
  display: inline-block;
  background-color: transparent;
  border: none;
  padding: 0;
  vertical-align: bottom;
  cursor: pointer;
}

.article button.bare.icon:hover,
.article button.bare.icon:hover i:before,
.article-categories button.bare.icon:hover,
.article-categories button.bare.icon:hover i:before {
  color: #fd635b;
}

.article-categories i.add.circle.icon {
  inline-size: initial;
}

.article i.edit.icon,
.article-categories i.edit.icon {
  margin-left: 8px;
  color: rgb(102, 102, 102);
}

.article i.edit.icon:hover,
.article i.trash.outline.icon:hover,
.article-categories i.edit.icon:hover,
.article-categories i.trash.outline.icon:hover {
  color: #fd635b;
}

.article-categories > .segment button:hover {
  color: #fd635b;
}

.article-categories i.trash.outline.icon {
  color: rgb(102, 102, 102);
}

.article-categories .child-categories {
  margin-left: 1em;
}

.article-categories > .category-grid,
.article-categories > .segment > .child-categories > .category-grid {
  display: flex;
  flex-wrap: wrap;
}
.article-categories > .category-grid > .column,
.article-categories > .segment > .child-categories > .category-grid > .column {
  width: 33%;
  float: left;
}

.article-categories > .category-grid:after,
.article-categories > .segment > .child-categories > .category-grid:after {
  content: '';
  display: block;
  margin-bottom: 1em;
  clear: both;
}
.article-categories div.ui.list a {
  color: rgb(102, 102, 102);
}

.article-categories div.ui.list a:hover {
  color: #fd635b;
}

.footer .category-grid {
  display: flex;
  flex-wrap: wrap;
}

.footer .category-grid > .column {
  width: 50%;
}

.footer .category-grid .segment {
  padding: 0;
}

.footer .category-grid .segment .ui.list {
  font-size: 0.92857143em;
  margin: 1em 0 0 0;
}

.footer .category-grid .segment .ui.list > .item {
  font-size: 1rem;
  line-height: 1rem;
}

.footer .category-grid .segment h4 > a {
  color: #343a40;
  text-decoration: underline;
}

.footer .category-grid .segment a {
  color: #343a40;
  text-decoration: underline;
}

.footer .category-grid .segment .item > a,
.footer .category-grid .segment h5 > a {
  font-size: 0.92857143em;
  font-weight: normal;
}

.footer .category-grid .ui.breadcrumb {
  display: block;
}

.footer .category-grid .segment a:hover,
.footer .category-grid .segment a.active {
  color: #fb625e;
}

.footer .category-grid .child-categories {
  padding-top: 3px;
}
