.gallery-wrapper {
  position: relative;
  -webkit-perspective: 768px;
  -moz-perspective: 768px;
  -o-perspective: 768px;
  perspective: 768px;
  height: 100%;
  padding: 0.5em;
}

.gallery-wrapper * {
  transform-style: preserve-3d;
}

.gallery {
  width: 100%;
  height: 100%;
  position: absolute;
}

.gallery-wrapper .gallery-cell {
  top: 0;
  left: 0;
  position: absolute;
  display: block;
  overflow: hidden;
  -webkit-transition: -webkit-transform 750ms ease, opacity 1500ms ease, filter 750ms ease;
  transition: transform 750ms ease, opacity 1500ms ease, filter 750ms ease;
  opacity: 0;
}

.gallery-wrapper .gallery-cell[data-placed] {
  opacity: 1;
}

.gallery-wrapper .gallery .gallery-cell.selected {
  opacity: 1;
}

.gallery-wrapper .gallery-cell.reflection {
  -webkit-mask-image: -webkit-gradient(
    linear,
    left top,
    left bottom,
    color-stop(0.35, transparent),
    color-stop(1, rgba(255, 255, 255, 0.3))
  );
  -moz-mask: -moz-gradient(
    linear,
    left top,
    left bottom,
    color-stop(0.35, transparent),
    color-stop(1, rgba(255, 255, 255, 0.3))
  );
}
