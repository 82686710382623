.Toastify__toast {
  background-color: rgba(52, 58, 64, 0.68);
  backdrop-filter: blur(30px);
  overflow: auto;
  min-height: 64px;
  max-height: 800px;
  min-width: 256px;
}

.bell-wrapper-SUCCESS {
  background-color: rgba(18, 173, 143, 0.14);
  margin-right: 28px;
  width: 48px;
  height: 48px;
}

.bell-wrapper-ERROR {
  background-color: rgba(242, 103, 103, 0.14);
  margin-right: 28px;
  width: 48px;
  height: 48px;
}

.bell-wrapper-WARNING {
  background-color: rgba(236, 198, 44, 0.14);
  margin-right: 28px;
  width: 48px;
  height: 48px;
}

.bell-wrapper-UPDATE {
  background-color: rgba(255, 255, 255, 0.14);
  margin-right: 28px;
  width: 48px;
  height: 48px;
}

.bell-SUCCESS {
  width: 38px;
  height: 38px;
  color: #12AD8F;
  opacity: 0.9;
}

.bell-ERROR {
  width: 38px;
  height: 38px;
  color: #F26767;
}

.bell-WARNING {
  width: 38px;
  height: 38px;
  color: #ECC62C;
}

.bell-UPDATE {
  width: 38px;
  height: 38px;
  color: #FFFFFF;
}

.toast-text-SUCCESS {
  color: #12AD8F;
  font-family: 'Muli', sans-serif;
  font-weight: bold;
}

.toast-text-ERROR {
  color: #F26767;
  font-family: 'Muli', sans-serif;
  font-weight: bold;
}

.toast-text-WARNING {
  color: #ECC62C;
  font-family: 'Muli', sans-serif;
  font-weight: bold;
}

.toast-text-UPDATE {
  color: #FFFFFF;
  font-family: 'Muli', sans-serif;
  font-weight: bold;
}

.toast-p {
  color: #FFFFFF;
  font-family: 'Muli', sans-serif;
}

.check-icon {
  width: 38px;
  height: 38px;
  color: rgba(255, 255, 255, 0.2);
}

.check-icon:hover {
  width: 38px;
  height: 38px;
  color: rgba(255, 255, 255, 1);
}

.toast-padding {
  padding: 8px;
}



@media (min-width:641px)  {
  /* portrait tablets, portrait iPad, landscape e-readers, landscape 800x480 or 854x480 phones */
  .Toastify__toast-container {
    width: 50%;
  }
}
@media (min-width:961px)  {
  /* tablet, landscape iPad, lo-res laptops ands desktops */
  .Toastify__toast-container {
    width: 35%;
  }
}
@media (min-width:1025px) {
  /* big landscape tablets, laptops, and desktops */
  .Toastify__toast-container {
    width: 35%;
  }
}
@media (min-width:1281px) {
  /* hi-res laptops and desktops */
  .Toastify__toast-container {
    width: 30%;
  }
}
