.unity-frame-container,
.unity-frame-container > div,
.unity-frame,
.unity-frame > div,
.unity-frame canvas {
  height: 100%;
  min-height: 100%;
  max-height: 100%;
}

.unity-frame {
  background-color: #fff;
}

.unity-frame > div {
  margin: 0px;
  padding: 0px;
  display: block;
}

.unity-frame canvas {
  display: block;
  margin: 0px auto 0px auto;
  min-width: 100%;
  width: 100%;
}
