.features .feature:before {
  content: '';
  display: block;
  float: left;
  width: 6rem;
  min-height: 6rem;
  height: 100%;
  background-position: top left;
  background-repeat: no-repeat;
  background-size: 5em;
  z-index: 10;
}

.features .feature:after {
  content: '';
  display: block;
  clear: both;
}

.features .feature > * {
  margin-left: 7rem;
}

.features .feature h3 {
  margin-bottom: 0.5em;
}

.major-feature {
  text-align: center;
}

.features .feature p {
  font-size: 1.1em;
}

.features .feature.immersive:before {
  background-image: url('./images/features/cube-inverted.png');
}

.features .feature.vr:before {
  background-image: url('./images/features/virtual-reality-ready-inverted.png');
}

.features .feature.interactive:before {
  background-image: url('./images/features/interactive-inverted.png');
}

.features .feature.endless:before {
  background-image: url('./images/features/storytelling-inverted.png');
}

.features .feature.space:before {
  background-image: url('./images/features/upload-digital-content.png');
}

.features .feature.content:before {
  background-image: url('./images/features/build-your-virtual-reality-space.png');
}

.features .feature.stories:before {
  background-image: url('./images/features/create-your-story.png');
}

.features .feature.share:before {
  background-image: url('./images/features/share.png');
}

.features .feature.social:before {
  background-image: url('./images/features/create-a-community.png');
}

.features .feature.engage:before {
  background-image: url('./images/features/socialize.png');
}

/**/

/*
explore
create
expand
socialize
*/
.features .feature.explore:before {
  background-image: url('./images/features/homeIcons/compass-inv.png');
  background-size: 65px !important;
  width: 65px;
  height: 65px;
}
.features .feature.create:before {
  background-image: url('./images/features/homeIcons/create-inv.png');
  background-size: 65px !important;
  width: 65px;
  height: 65px;
}
.features .feature.expand:before {
  background-image: url('./images/features/homeIcons/expand-inv.png');
  background-size: 65px !important;
  width: 65px;
  height: 65px;
}
.features .feature.socialize:before {
  background-image: url('./images/features/homeIcons/socialize-inv.png');
  background-size: 65px !important;
  width: 65px;
  height: 65px;
}

.features .feature.customize:before {
  background-image: url('./images/features/homeIcons/customize-inv.png');
}
.features .feature.diy:before {
  background-image: url('./images/features/homeIcons/diy-inv.png');
}
.features .feature.private-space-lock:before {
  background-image: url('./images/features/homeIcons/private-space-lock.png');
}
.features .feature.custom-3d-space:before {
  background-image: url('./images/features/homeIcons/customize.png');
}
.features .feature.curatorial:before {
  background-image: url('./images/features/homeIcons/curatorial.png');
}
.features .feature.virtual-events:before {
  background-image: url('./images/features/homeIcons/virtual-events.png');
}
.features .feature.embed:before {
  background-image: url('./images/features/homeIcons/embed.png');
}
/**/

.features .feature p {
  margin-bottom: 1.5em;
}

body .featured.ui.inverted.segment,
body .featured.ui.segment .segment {
  background-color: transparent;
}

body .featured.ui.inverted.segment a,
body .featured.ui.inverted.segment button {
  pointer-events: auto;
}

body .featured.segment h1.ui.header {
  font-size: 4.5em;
}

@media only screen and (max-width: 767px) {
  body .featured.segment h1.ui.header {
    font-size: 2.5em !important;
  }
}

.homepage-private-image {
  width: 100%;
  max-height: 100%;
}

.homepage-header-container {
  position: absolute;
  top: 20rem;
  width: 100%;
}

@media only screen and (min-width: 1600px) {
  .homepage-header-container {
    padding-left: 20rem;
    padding-right: 20rem;
  }
}

@media only screen and (min-width: 1200px) {
  .homepage-header-container {
    padding-left: 5em;
    padding-right: 5rem;
  }
}

.homepage-header-title {
  text-align: center;
  font-size: 4.5em !important;
  color: #ffffff;
}

@media only screen and (max-width: 768px) {
  .homepage-header-title {
    font-size: 3em !important;
    line-height: 3.6rem !important;
  }
}

.homepage-header-subtitle {
  text-align: center;
  color: #ffffff;
}

.panorama-viewer,
.featured-video {
  position: absolute;
  width: 100%;
  max-height: 100%;
  min-height: 100%;
  min-width: 100%;
  left: 0;
  z-index: -1;
  cursor: grab;
  filter: saturate(60%) brightness(50%);
}

/* @media only screen and (max-width: 1116px) {
  .panorama-viewer,.featured-video {
  top: 40vh;
  }
}
@media only screen and (max-width: 768px) {
  .panorama-viewer,.featured-video {
  top: 5vh;
  }
} */

.ui.segment.section {
  margin-top: 2em;
}

.ui.segment.section h2.ui.header {
  text-align: left;
}

.message.call-to-action {
  padding: 2.5em;
  line-height: 2em;
  text-align: center;
  background-color: #343a40;
  margin: 1em -1em -1em -1em;
}

.message.call-to-action .ui.button {
  margin: 1em;
  font-size: 1.25em;
}

.message.call-to-action .ui.header {
  margin: 15px;
}

.cover .storeicons {
  float: right;
  padding-top: 5em;
  padding-right: 0.4em;
  width: 364px;
  display: flex;
  justify-content: space-between;
}

.storeicons {
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin: auto;
}

@media only screen and (max-width: 450px) {
  .cover .storeicons {
    position: absolute;
    bottom: 4.5em;
    padding-right: 0em;
    width: 100%;
    justify-content: space-evenly;
  }
  .storeicons {
    justify-content: center;
    margin: auto;
    flex-direction: row;
    align-items: center;
    transform: scale(0.9);
  }
  .storeicons > a {
    margin-bottom: 5px;
  }
}

.message.call-to-action h3.ui.header {
  color: #fcfcfc;
  font-size: 2em;
  margin-bottom: 0;
  display: inline-block;
  font-weight: 500;
}

.tour_feature p {
  margin-bottom: 2em;
}

.image-right {
  float: right;
  padding-right: 5%;
}

.feature-text-left {
  padding-left: 5% !important;
  padding-right: 1% !important;
  vertical-align: middle;
}

.image-left {
  padding-left: 5%;
}

.feature-text-right {
  padding-left: 1% !important;
  padding-right: 5% !important;
  vertical-align: middle;
}

.ui.segment.featured-features {
  pointer-events: none;
  position: absolute;
  top: calc(100vh - 0.5em);
  left: 50%;
  transform: translate(-50%, -100%);
  /* background-color: rgba(255,255,255,0.5); */
  text-shadow: 2px 2px 8px rgba(0, 0, 0, 0.75);
}

.ui.segment.inverted.featured-features {
  background-color: transparent;
}

.ui.segment.featured-features .feature > * {
  margin-left: 0;
}

.ui.segment.featured-features .feature > h3 {
  padding-bottom: 4rem;
}

.ui.segment.featured-features .feature:before {
  height: auto;
  width: 5rem;
  min-height: 5rem;
  background-size: 5rem;
  position: absolute;
  top: 3.5em;
  left: 50%;
  transform: translate(-50%, 0);
}

@media only screen and (max-width: 767px), only screen and (max-height: 710px) {
  .ui.segment.featured-features {
    pointer-events: auto;
    position: static;
    transform: none;
    min-width: auto;
    text-shadow: none;
  }
  .ui.segment.inverted.featured-features,
  .ui.segment.inverted.featured-features h3 {
    color: #12242e;
  }
  .features .feature.immersive:before {
    background-image: url('./images/features/cube.png');
  }
  .features .feature.vr:before {
    background-image: url('./images/features/virtual-reality-ready.png');
  }
  .features .feature.interactive:before {
    background-image: url('./images/features/interactive.png');
  }
  .features .feature.endless:before {
    background-image: url('./images/features/storytelling.png');
  }
  /*
  explore
  create
  expand
  socialize
  */
  .features .feature.explore:before {
    background-image: url('./images/features/homeIcons/compass.png');
    background-size: 65px !important;
    width: 65px;
    height: 65px;
  }
  .features .feature.create:before {
    background-image: url('./images/features/homeIcons/create.png');
    background-size: 65px !important;
    width: 65px;
    height: 65px;
  }
  .features .feature.expand:before {
    background-image: url('./images/features/homeIcons/expand.png');
    background-size: 65px !important;
    width: 65px;
    height: 65px;
  }
  .features .feature.socialize:before {
    background-image: url('./images/features/homeIcons/socialize.png');
    background-size: 65px !important;
    width: 65px;
    height: 65px;
  }
}

@keyframes appear {
  0% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }
}

@keyframes disappear {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(0);
  }
}

.un-animation-expand {
  /* The animation code */
  transform-origin: top right;
  animation: appear 0.3s linear forwards;
}

.un-animation-collapse {
  transform-origin: top right;
  animation: disappear 0.3s linear forwards;
}
