#maintenanceBanner-text {
  width: 80%;
  text-align: left;
  margin: auto;
  color: aliceblue;
  font-weight: 400;
  font-size: 1.2rem;
  padding-top: 1.2rem;
  padding-bottom: 1.2rem;
  padding-left: 32rem;
  padding-right: 32rem;
}

.ui.icon.message > .icon:not(.close) {
  font-size: 1.5em;
}
.ui.icon.message {
  position: fixed;
  right: 32px;
  top: 84%;
  background-color: #6f7478eb;
  display: flex;
  align-items: center;
  color: white;
  font-size: 14px;
}

@media screen and (max-width: 2100px) {
  #maintenanceBanner-text {
    padding-left: 3rem;
    padding-right: 3rem;
  }
  .ui.icon.message {
    width: 640px;
  }
}

@media screen and (max-width: 1497px) {
  .ui.icon.message {
    width: 540px;
  }
}
@media screen and (max-width: 1138px) {
  .ui.icon.message {
    width: 509px;
  }
}

@media screen and (max-width: 768px) {
  #maintenanceBanner-text {
    font-size: 1.2rem;
    padding-left: 1rem;
    padding-right: 1rem;
  }
  .ui.icon.message {
    width: 97%;
    right: 10px;
  }
}
@media screen and (max-width: 453px) {
  .ui.icon.message {
    width: 97%;
    top: 70%;
    right: 6px;
  }
}
@media screen and (max-width: 699px) {
  .contribution-link-wrapper {
    flex: unset !important;
    text-align: center !important;
    flex-basis: 100% !important;
    margin-top: 22px;
  }
  .contribution-link {
    margin: 22px auto !important;
  }
  .contribution-banner-text {
    flex: 1;
  }
}

.maintenanceBanner-div {
  position: fixed;
  background-color: #f35d5d;
  z-index: 5;
  bottom: 0;
  width: 100%;
}

.x-icon-banner {
  float: right;
  padding-right: 18px;
  padding-top: 7px;
  color: transparent;
  text-shadow: 0 0 0 aliceblue;
  cursor: pointer;
  font-size: 0.8rem;
}

i.inverted.circular.icon {
  background-color: #84888b !important;
  color: #fff !important;
}

.update-notification-banner {
  font-weight: 400;
}
.notif-link {
  color: white;
  text-decoration: underline;
}
.notif-link:hover {
  color: #f2cdcd;
}
.check-icon {
  cursor: pointer;
}
.further-assistance {
  display: flex;
  align-items: center;
}
.sales-email-img {
  margin-top: 3px;
  height: 19px;
  margin-left: 5px;
}
