.addreply {
  margin-left: 4em;
}

.comment-container {
  position: relative;
}

.comment-textarea .ui.button,
.ui.comments .ui.reply.form .ui.button {
  vertical-align: bottom;
  display: inline-block;
}

.ui.form .comment-textarea textarea,
.ui.comments .ui.reply.form textarea {
  max-width: 540px;
  margin-right: 0.5em;
  margin-bottom: 0.5em;
  max-height: 4em;
  min-height: 4em;
}

.ui.form .ui.avatar {
  float: left;
}

.avatar-area {
  height: 65px;
  float: left;
}

.ui.form .ui.avatar,
.ui.form .ui.avatar img,
.ui.comments .ui.avatar,
.ui.comments .ui.avatar img {
  width: 4em;
  height: 4em;
  object-fit: cover;
}

.ui.comments {
  max-width: 100%;
}

.ui.comments .comment-replies {
  position: relative;
}

.ui.comments .ui.comment a {
  cursor: pointer;
  margin-right: 0.5em;
}

.ui.comments .ui.comment {
  margin-top: 0.5em;
  line-height: 1.5em;
}

.ui.comments .ui.comment .ui.content {
  margin-left: 4.5em;
}

.ui.comments .ui.comment:after {
  content: '';
  display: block;
  clear: both;
}

.ui.comments .ui.comments {
  margin: 0;
  padding: 0;
}

.ui.comments .ui.comments .ui.avatar,
.ui.comments .ui.comments .ui.avatar img {
  width: 2.5em;
  height: 2.5em;
}

.ui.comments .ui.comments .ui.comment .ui.content {
  margin-left: 3em;
}

.ui.comments .comment a.author {
  font-size: 1.3em;
}

.ui.comments .comment a.author:hover {
  color: #fd635b;
}
