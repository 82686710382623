.title {
  color: #343a40;
}

.description {
  display: block;
  font-family: 'Muli', sans-serif;
  color: rgba(52, 58, 64, 0.7);
}
.icon-legends {
  color: #343a40;
  opacity: 1;
}
.username {
  display: block;
  font-family: 'Muli', sans-serif;
  color: rgba(52, 58, 64, 1);
}
.categories {
  color: rgba(52, 58, 64, 1);
  background-color: rgba(52, 58, 64, 0.1);
  font-family: 'Muli', sans-serif;
  text-transform: none;
}

.main-div {
  width: 100%;
  position: relative;
}

.grid-footer {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 0;
  position: absolute;
  bottom: 26px;
}

@media (min-width: 960px) {
  /* md */
  .root {
    margin: 30px auto 30px;
    width: 90%;
    display: flex;
  }
  .title {
    font: normal normal bold 20px/24px Muli;
  }
  .exhibition-card-image {
    /* xl size * 0.9 */
    width: 448px;
    height: 100%;
    min-width: 448px;
    min-height: 291px;
    padding-right: 20px;
    padding-bottom: 0;
    object-fit: cover;
  }
  .description {
    font-size: 18px;
  }
  .icon-legends {
    font: normal normal bold 14px/21px Muli;
    letter-spacing: 0;
  }
  .username {
    font-size: 14px;
  }
  .categories {
    border-radius: 20px;
    width: 140px;
    height: 32px;
    font-size: 14px;
    margin: 5px 5px;
  }
  .white-div {
    height: 81px;
  }
}

@media (min-width: 1280px) {
  /* lg */
  .root {
    margin: 30px auto 30px;
    width: 90%;
    display: flex;
  }
  .title {
    font: normal normal bold 24px/33px Muli;
  }
  .exhibition-card-image {
    /* xl size * 0.8*/
    object-fit: cover;
    width: 398px;
    min-width: 398px;
    min-height: 258px;
    padding-right: 20px;
    padding-bottom: 0;
  }
  .description {
    font-size: 20px;
  }
  .icon-legends {
    font: normal normal bold 14px/21px Muli;
    letter-spacing: 0;
  }
  .username {
    font-size: 16px;
  }
  .categories {
    border-radius: 20px;
    width: 140px;
    height: 32px;
    font-size: 14px;
    margin: 5px 10px;
  }
  .white-div {
    height: 90px;
  }
}

@media (min-width: 1920px) {
  /* xl */
  .root {
    margin: 30px auto 30px;
    width: 90%;
    height: 323px;
    display: flex;
  }
  .title {
    font: normal normal normal 26px/33px Muli;
  }
  .exhibition-card-image {
    width: 498px;
    min-width: 498px;
    min-height: 323px;
    padding-right: 20px;
    object-fit: cover;
    padding-bottom: 0;
  }
  .description {
    font-size: 22px;
  }
  .icon-legends {
    font: normal normal bold 14px/21px Muli;
    letter-spacing: 0;
  }
  .username {
    font-size: 16px;
  }
  .categories {
    border-radius: 20px;
    width: 140px;
    height: 32px;
    font-size: 14px;
    margin: 5px 10px;
  }
  .white-div {
    height: 99px;
  }
}
