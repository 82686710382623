.donate-button {
  /* float: left */
  border-radius: 20px;
  border-style: none;
  color: black;
  font-weight: 400;
  /* color: white; */
  font-weight: 600;
  background-color: #fdc439;
  /* background-color: #fe625b; */
  width: 232px;
  height: 37px;
  cursor: pointer;
  margin-top: 90px;
  /* margin-left: 20px; */
}

#contribute-column {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
  padding-left: 20px;
  padding-right: 11px;
  width: 58%;
}

.contribute-form-container .ui.column.left.aligned {
  padding-left: 0;
  padding-right: 0;
}

/* #contribute-cover .ui.basic.left.aligned.segment {
  margin-bottom: -13px;
} */

.contribute-form-container {
  margin-top: 0 !important;
}

.contribute-form-container .field.required:not(:last-child) {
  padding-right: 14px;
}

.contribute-modal-cover-column {
  padding: 0 !important;
  /* background: #343a40 0% 0% no-repeat padding-box; */
  background: #747a80e1 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #00000029;
  /* border-radius: 6px 0px 0px 6px; */
  opacity: 0.8;
  background-image: url('./images/mich_norm.jpg');
  background-position: top;
  background-repeat: no-repeat;
  background-size: auto 346px;
  width: 302px !important;
}

.contribute-cover-column-header {
  font-size: 2.675rem !important;
  line-height: 1.26 !important;
}

.contribute-modal-overlay {
  width: 741px !important;
  border-radius: 6px;
  /* overflow-y: scroll !important;
    max-height: none !important; */
  overflow-x: hidden;
  top: 1.2%;
}

.overlay-dialog .contribute-modal-overlay > div {
  max-height: none !important;
}

.contribute-modal-overlay .ui.square.tiny.icon.button.secondary.basic.close-button {
  box-shadow: none !important;
  color: rgba(0, 0, 0, 0.6) !important;
  font-size: 1rem;
  font-weight: 100 !important;
}

.contribute-modal-donate-container {
  margin-left: 14px;
}

.contribute-modal-donate-container input {
  font-family: 'Muli', sans-serif;
  font-weight: 700;
  margin-top: 1px;
}

.contribute-assets {
  font-size: 1.5em;
  text-align: left;
  padding: 18px 0 0 15px;
}
.contribute-assets-text {
  font-size: 1rem;
  line-height: 1.2;
  text-align: left;
  padding: 0;
  margin: 0 auto;
  font-weight: 500;
  letter-spacing: 0px;
  color: #343a40;
  opacity: 0.7;
  padding-right: 10px;
}

#contribute-headers-2,
#contribute-headers-3 {
  font-size: 1.15rem;
  line-height: 1.56 !important;
  color: #ffffff;
  opacity: 0.7;
  line-height: 1.2;
  /* padding-left: 18px !important;
    padding-right: 18px !important; */
}
#contribute-headers-1 {
  font-size: 1.625rem;
  font-weight: 700;
  color: white;
  /* padding-left: 18px !important;
    padding-right: 18px !important; */
}

.contribute-form-container button {
  width: 300px !important;
  background-color: #fe625b !important;
}

.contribute-modal-two-column-container::-webkit-scrollbar {
  display: none;
}

.contribute-modal-two-column-container {
  -ms-overflow-style: none;
}

.overlay-dialog .overlay-dialog-content.contribute-modal-overlay {
  max-width: none;
}

.layout.default {
  position: relative;
}

.contribute-form-container .ui.segment.vertical.form-buttons {
  padding-top: 20px !important;
  float: none !important;
  display: flex;
  flex-direction: column;
}

.contribute-form-container button {
  width: 260px !important;
  margin-left: auto !important;
  margin-right: auto !important;
}

@media screen and (max-width: 767px) {
  #contribute-modal-cover-column {
    width: 100% !important;
    padding: 0 !important;
  }

  #contribute-cover {
    display: flex;
    flex-direction: column;
  }

  .contribute-modal-overlay {
    padding: 0 !important;
  }
  #contribute-column {
    width: 100% !important;
  }

  .contribute-modal-donate-container {
    margin-left: auto;
    margin-right: auto;
  }

  .contribute-modal-donate-container form {
    display: flex;
  }

  .contribute-modal-donate-container input {
    margin-left: auto;
    margin-right: auto;
  }

  .contribute-form-container .ui.segment.vertical.form-buttons {
    padding-bottom: 10px !important;
  }
  /*
  .contribute-form-container button {
    width: 260px !important;
    margin-left: auto !important;
    margin-right: auto !important;
  } */

  .overlay-dialog .overlay-dialog-content.contribute-modal-overlay {
    max-width: 94%;
    top: 0.75%;
  }
}

@media screen and (max-width: 600px) {
  .contribute-modal-donate-container input {
    margin-top: 12px;
  }
  .contribute-form-container #g-recaptcha {
    overflow: scroll;
  }
  .overlay-dialog .overlay-dialog-content.contribute-modal-overlay {
    max-width: 94%;
    top: 0.75%;
  }
}

@media screen and (max-width: 375px) {
  .contribute-form-container button {
    width: 200px !important;
    height: 50px !important;
  }
}

/* freeze scrolling on large devices when modal up */
@media screen and (min-height: 660px) and (min-width: 768px) {
  .body-freeze-scrolling {
    overflow: hidden !important;
  }
}
