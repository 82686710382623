/* @import url('https://fonts.googleapis.com/css?family=Quicksand:300'); */

#root {
  min-height: 100%;
  overflow-x: hidden;
}

body {
  font-family: 'Muli','Roboto', sans-serif;
  word-wrap: break-word;
  padding-right: 0 !important;
  overflow: auto;
}

body,
body .ui.inverted.segment {
  /* color: #ffffff; */
  background-color: #fcfcfc;
}

main {
  color: #212529;
  background-color: #ffffff;
}

h1,
h2,
h3,
h4,
h5 {
  font-family: 'Muli', sans-serif;
}

.ui.vertical.footer.inverted.segment.layout-footer {
  border-top: 1px solid #e0e1e2;
}

.ui.header {
  color: #212529;
}

.copyrights {
  color: #212529 !important;
  margin: 0 !important;
}

h1.ui.header,
h2.ui.header {
  font-family: 'Muli', sans-serif;
  letter-spacing: 0.05em;
  color: #212529;
  text-align: center;
}

h1.ui.header {
  font-size: 2.8rem;
  line-height: 5.6rem;
  margin-bottom: 0.5em;
}

h1.ui.header-contribute {
  font-size: 2.5rem;
  line-height: 4.6rem;
  margin-bottom: 0.5em;
  text-align: left;
  padding-left: 13px;
}

h1.ui.header:first-child {
  margin-top: 0.25em;
}

h2.ui.header {
  font-size: 3.2rem;
  margin-bottom: 0.75em;
  font-weight: 500;
  font-family: 'Muli', sans-serif;
}

h3.ui.header {
  font-size: 2rem;
  margin-bottom: 0.5em;
  font-weight: 200;
  font-family: 'Muli', sans-serif;
}

h4.ui.header {
  font-size: 1.2rem;
  font-family: 'Muli', sans-serif;
}

.ui.inverted h1,
.ui.inverted h2,
.ui.inverted h3,
.ui.inverted h4,
.ui.inverted h5 {
  color: #fff;
}

.ui.inverted .overlay-dialog h1,
.ui.inverted .overlay-dialog h2,
.ui.inverted .overlay-dialog h3,
.ui.inverted .overlay-dialog h4,
.ui.inverted .overlay-dialog h5,
.toolbar .toolbar-content .overlay-dialog .ui label {
  color: #0c2939;
}

@media only screen and (max-width: 767px) {
  h2.ui.header {
    font-size: 2rem;
    font-weight: 600;
  }
}

p {
  font-size: 1.1em;
}

hr {
  opacity: 0.2;
}

.ui.button {
  font-family: 'Muli', sans-serif;
  font-weight: bold;
  word-wrap: normal;
  border-radius: 0 !important;
}

.ui.button.basic {
  font-weight: bold;
  word-wrap: normal;
  border-radius: 0;
}

.item .ui.button {
  font-weight: normal;
  border-radius: 0;
}

.layout-header.menu.inverted {
  box-shadow: none;
  border-bottom: none;
  background-color: #0c2939;
  transition: 0.3s all;
}

.layout-header.menu .item {
  font-size: 1.06em;
  font-weight: bold;
  line-height: 0.9em;
}

.layout-header.menu > .right.dropdown.item {
  padding-right: 1em;
}

/* .layout-header.menu > .item > .ui.button {
  margin-right: 0.25em;
} */

.separator {
  border-right: 1px solid #e0e1e2;
  padding: 0.3em 0px;
  margin-right: 0.5em;
}

@media only screen and (max-width: 1250px) {
  .layout-header.menu .hidden {
    display: none !important;
  }
  .layout-header.menu .item.brand {
    width: auto;
    padding-right: 1em;
  }
}

.mobnav {
  display: none !important;
}

@media only screen and (max-width: 1250px) {
  .mobnav {
    display: flex !important;
  }
}

.firefix-middle {
  height: 100%;
}

.layout-header.menu > .container > .item > .button > .text > .icon {
  margin-right: 0;
  margin-left: 0.5rem;
}

.layout-header.menu > .container > .item > .dropdown.button {
  overflow: visible;
}

.layout-footer {
  background-color: #ebebeb;
}

.layout-body {
  background-color: #fff;
  position: relative;
  min-height: 100vh;
  padding: 5.005rem 0 0 0;
}

@media only screen and (max-width: 720px) {
  .layout-body {
    padding: 3.495rem 0 0 0;
  }
}

.ui.menu {
  border: 0px solid;
  border-bottom: 1px solid #e0e1e2;
  font-family: 'Muli', sans-serif;
  -webkit-box-shadow: 0 0px 0px 0 rgba(34, 36, 38, 0.15);
  box-shadow: 0 0px 0px 0 rgba(34, 36, 38, 0.15);
}

.layout-header.ui.menu {
  height: 4rem;
  z-index: 1001;
  width: 100% !important;
  margin-left: 0em !important;
  margin-right: 0em !important;
}

.ui.secondary.menu .active.item {
  color: #fe625b;
}

.divcenter {
  display: flex;
  align-items: center;
  justify-content: center;
}

#divright {
  width: auto !important;
}

.layout-header.menu .item.brand {
  font-size: 1.7rem;
  /* width: 50%; */
  padding-left: 0.4em;
}

.layout-header.menu .item.centered {
  transform: translateX(-50%);
}

.ui.menu:not(.secondary):not(.text):not(.tabular):not(.borderless)
  > .container
  > .item:not(.right):not(.borderless).brand:first-child {
  border-left: 0px;
}

body .ui.inverted.menu .active.item {
  background-color: #1f4960;
}

.layout-header.ui.menu .item:before {
  width: 0px;
}

.ui.menu .item {
  color: #343a40;
  font-weight: 400;
  font-family: 'Muli', sans-serif;
  padding: 0.8em 0.8em;
}

.ui.menu .active.item {
  color: #fe625b;
  background-color: rgba(0, 0, 0, 0);
}

.ui.menu .active.item:hover {
  color: #fe625b;
  background-color: rgba(0, 0, 0, 0);
}

.ui.link.menu .item:hover,
.ui.menu .dropdown.item:hover,
.ui.menu .link.item:hover,
.ui.menu a.item:hover {
  color: #fe625b;
  background-color: rgba(0, 0, 0, 0);
}

.layout-header.menu .item.brand img {
  height: 2em;
  width: auto !important;
  vertical-align: bottom;
}

.layout-header.menu .item.brand > a,
.layout-footer .item.brand > a {
  display: inline-block;
}

.layout-header.menu.ui.inverted a.item.brand:hover {
  background-color: transparent;
}

.layout.full {
  min-height: 100%;
  min-width: 100%;
  background-image: url('./images/mich_blur.jpg');
  background-repeat: no-repeat;
  background-position: top center;
  background-attachment: fixed;
  background-size: cover;
  position: absolute;
  top: 0px;
  left: 0px;
  padding: 2em;
  line-height: calc(100vh - 4em);
  text-align: center;
}

.layout.full .layout-body {
  display: inline-block;
  vertical-align: middle;
  margin: 0 auto 0 auto;
  line-height: 1em;
  padding: 0px;
  min-height: 25%;
  max-width: 1024px;
  width: 100%;
  border-radius: 8px;
}

.layout.full .layout-body .grid {
  background-color: #fff;
  /* border-radius: 8px; */
}

.layout.full .layout-body .grid > .column {
  padding: 1em;
}

.layout.full .layout-body .cover-column > div {
  height: 100%;
  /* border-top-left-radius: 8px;
  border-bottom-left-radius: 8px; */
  text-align: center;
  position: relative;
  background-color: rgba(0, 0, 0, 0.25);
}

.layout.full .layout-body .cover-column:before {
  content: '';
  background-image: url('./images/mich_norm.jpg');
  background-repeat: no-repeat;
  background-position: top center;
  background-attachment: fixed;
  background-size: cover;
  height: 100%;
  width: 100%;
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 0;
  /* border-top-left-radius: 8px;
  border-bottom-left-radius: 8px; */
}

@media only screen and (max-width: 767px) {
  .layout.full .layout-body .cover-column > div,
  .layout.full .layout-body .cover-column:before {
    border-top-right-radius: 8px;
    border-bottom-left-radius: 0px;
  }
}

.layout.full .layout-body .cover-column h4 {
  font-weight: normal;
  font-size: 1.7em;
}

.layout.full .layout-body .cover-column {
  padding-right: 0px;
  color: #ffffff;
}

.white-text {
  color: #ffffff !important;
}

.layout.full .layout-body .cover-column h4.login-message {
  font-size: 1.5em;
  position: absolute;
  bottom: 2em;
  margin: auto;
  left: 50%;
  width: 100%;
  transform: translate(-50%, 0);
}

.layout.full .layout-body .cover-column h1 {
  font-size: 3em;
  text-transform: uppercase;
}

.layout.full .layout-body .cover-column h4.login-message a {
  font-size: 1.25em;
}

p.login-message {
  font-size: 1.1em;
  padding-top: 15px;
}

p.welcome-message {
  font-size: 1.2rem;
  padding-top: 15px;
}

p.not-verified_message {
  font-size: 1.2rem;
  white-space: pre-line;
  color: #fe625b;
  margin-bottom: 20px;
}

.layout.full .layout-body .ui.form button {
  display: block;
  margin: 1em auto;
}

body a,
.ui.breadcrumb a {
  color: #fe625b;
  cursor: pointer;
}

body a:hover,
.ui.breadcrumb a:hover {
  color: #912d2c;
}

.layout-sidebar {
  background-color: #e8e8e8;
}

.layout-sidebar .ui.menu {
  background-color: transparent;
  border: none;
  box-shadow: none;
}

.featured.ui.inverted.segment {
  height: 100vh;
  padding: 0;
  margin-top: -4rem;
}

@media only screen and (max-width: 360px) {
  .featured.ui.inverted.segment {
    height: 120vh;
  }
}

@media only screen and (max-width: 330px) {
  .featured.ui.inverted.segment {
    height: 129vh;
  }
}

.featured.ui.segment .segment.cover {
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 0;
}

.featured.ui.segment .ui.container {
  text-align: center;
  margin: 45vh auto 0 auto;
  transform: translateY(-75%);
}

.ctaplacement {
  height: 15em;
  align-items: center;
  justify-content: center;
}

.featured .ui.header {
  margin-bottom: 0em;
  /* text-shadow: 2px 2px 8px rgba(0, 0, 0, 0.75); */
}

.featured ui.header .subtitle {
  display: block;
  font-size: 1.5rem;
  line-height: 2rem;
}

.featured .ui.button {
  margin-top: 2em;
}

ui.segment.vertical.padded {
  padding-left: 0;
  padding-right: 0;
}

.main.header h1,
.main.header h3 {
  text-align: center;
}

.main.header h1 {
  text-transform: uppercase;
  font-size: 1.4em;
  margin: 0.5em 0 0 0;
}

.main.header h3 {
  font-weight: normal;
}

.text.small {
  font-size: 0.8em;
  opacity: 0.6;
}

.layout-body .ui.inverted.dimmer {
  position: absolute;
}

.layout-body .ui.inverted.dimmer .ui.loader {
  font-size: 2em;
}

.ui.video-loader {
  font-size: 2em;
}

.layout-body .ui.grid > .stretched.column {
  padding: 0px !important;
}

.buttons .button.label,
.buttons .button.label:hover {
  color: rgba(0, 0, 0, 0.6) !important;
  background-color: #ccc;
  cursor: auto;
}

.ui.image.medium video {
  max-width: 300px;
}

.ui.segments > .ui.segments {
  margin: 0.5rem 1rem;
}

.ui.segments.nonborder,
.ui.segments.nonborder .segment {
  border: none !important;
  box-shadow: none;
}

.ui.nonborder {
  border: none;
}

.ui.divider {
  margin: 0;
}

.ui.horizontal.segments {
  margin: 0;
}

.footer .help-text label {
  font-size: 0.8em;
  color: #212529 !important;
}

.footer .help-text.ui.segment {
  margin-top: 0px;
  padding: 0.5em 0 0 0;
}

.footer h4 {
  text-transform: uppercase;
  color: #212529 !important;
}

.footer .list > .item {
  padding-right: 0.5em;
}

.footer .ui.action.input input {
  max-width: calc(100% - 7em);
}

/* @media only screen and (max-width: 767px) {
  .ui.stackable.grid.stretched {
    margin-right: -1rem !important;
    margin-left: -1rem !important;
  }
} */

a.read-more {
  cursor: pointer;
}

.text.center.aligned {
  text-align: center;
}

[data-tooltip]:before,
[data-tooltip]:after {
  font-size: 0.9rem;
  background-color: #1b1c1d !important;
  border-radius: 0px;
  font-weight: bold;
  z-index: 1002;
  pointer-events: none !important;
}

[data-tooltip]:after {
  padding: 0.5rem;
}

[data-tooltip][data-color='red']:before,
[data-tooltip][data-color='red']:after {
  background-color: #db2828 !important;
}

[data-tooltip][data-color='purple']:before,
[data-tooltip][data-color='purple']:after {
  background-color: #b413ec !important;
}

[data-tooltip][data-color='teal']:before,
[data-tooltip][data-color='teal']:after {
  background-color: #009c95 !important;
}

.multiselect-input .tag {
  position: relative;
  display: inline-block;
  padding: 5px 12px;
  margin: 3px;
  border-radius: 15px;
  line-height: 19px;
  color: #767676;
  cursor: pointer;
  transition: transform 0.1s linear;
  background-color: #e8e8e8;
  font-size: 0.9em;
  border-width: 0;
}

input[type='color'][disabled] {
  opacity: 0.4;
}

.ui.checkbox a {
  color: #343a40;
  text-decoration: underline;
}

.ui.checkbox a:hover {
  color: #fe625b;
}

.random-text {
  display: block;
  line-height: 1.5;
}

.random-text .loading-author:before {
  content: '-';
}

.random-text .loading-author {
  display: block;
  color: #767676;
}

.pager-buttons {
  margin-top: 20px;
  text-align: center;
  padding-bottom: 2em;
}

.pager-buttons .pager-button {
  cursor: pointer;
}

.ui.modal {
  transform: translate(0, -50%);
}

.ui.button.primary,
.ui.button.teal {
  background-color: #fd635b;
}

.ui.button.primary:hover,
.ui.button.teal:hover,
.ui.button.primary:focus,
.ui.button.teal:focus {
  background-color: #912d2c;
}

.ui.button.basic.primary,
.ui.button.basic.teal {
  box-shadow: 0 0 0 1px #343a40 inset !important;
  color: #343a40 !important;
}

.ui.button.basic.primary:hover,
.ui.button.basic.primary:focus,
.ui.button.basic.teal:hover,
.ui.button.basic.teal:focus {
  box-shadow: 0 0 0 5px #fcfcfc inset !important;
  transition: color 0.5s, box-shadow 0.5s;
  color: #fcfcfc !important;
}

.ui.button.secondary {
  background-color: #343a40;
}

.ui.button.secondary:hover,
.ui.button.secondary:focus {
  background-color: #fd635b;
  transition: background-color 0.5s;
}

.ui.button.call {
  background-color: rgba(0, 0, 0, 0);
  color: #fcfcfc;
  box-shadow: 0 0 0 5px #fcfcfc inset !important;
}

.ui.button.call:hover,
.ui.button.call:focus {
  background-color: #fcfcfc;
  color: #343a40;
  background-color: #fcfcfc;
}

.ui.button.basic.secondary {
  box-shadow: 0 0 0 1px #dfdfdf inset !important;
  color: #343a40 !important;
}

.ui.button.basic.secondary:hover,
.ui.button.basic.secondary:focus {
  box-shadow: 0 0 0 1px #dfdfdf inset !important;
  transition: color 0.2s;
  color: #fe625b !important;
}

.ui.button.withtooltip,
.change-password-disabled.withtooltip,
.privacy-settings-disabled.withtooltip,
.create-mobile-disabled.withtooltip {
  position: relative;
}

.ui.button.withtooltip .tooltiptext,
.change-password-disabled.withtooltip .tooltiptext,
.privacy-settings-disabled.withtooltip .tooltiptext,
.create-mobile-disabled.withtooltip .tooltiptext {
  visibility: hidden;
  width: 240px;
  background-color: #f35d5d;
  color: #fff;
  text-align: left;
  font-size: 0.98rem;
  font-weight: 700;
  padding: 5px 5px;
  border-radius: 6px;
  position: absolute;
  z-index: 100;
  top: 120%;
  left: 50%;
  margin-left: -120px;
  /* Use half of the width (120/2 = 60), to center the tooltip */
  opacity: 1;
}

.tooltiptext.mobile {
  left: 20% !important;
}

.signup-content-freeze {
  margin-top: -60px;
  margin-bottom: 40px;
  padding-left: 60px;
  padding-right: 60px;
  margin-left: auto;
  margin-right: auto;
  color: #f35d5d;
}

.signin-content-freeze {
  margin-top: -40px;
  margin-bottom: 40px;
  padding-left: 60px;
  padding-right: 60px;
  margin-left: auto;
  margin-right: auto;
  color: #f35d5d;
}

.ui.button.disabled.create-button,
.ui.button.disabled.edit-button,
.ui.button.disabled.edit-profile {
  pointer-events: all !important;
  background-color: rgb(39, 41, 42, 0.45) !important;
  opacity: 1 !important;
  z-index: 1;
}

.ui.button.disabled.create-button.withtooltip:hover .tooltiptext,
.ui.button.disabled.edit-button.withtooltip:hover .tooltiptext,
.ui.button.disabled.delete-button.withtooltip:hover .tooltiptext,
.ui.button.disabled.edit-profile.withtooltip:hover .tooltiptext,
.change-password-disabled.withtooltip:hover .tooltiptext,
.privacy-settings-disabled.withtooltip:hover .tooltiptext,
.create-mobile-disabled.withtooltip:hover .tooltiptext {
  visibility: visible;
}

.ui.button.disabled.delete-button {
  pointer-events: all !important;
  color: rgb(254, 98, 91, 0.45) !important;
  box-shadow: 0 0 0 1px rgb(254, 98, 91, 0.45) inset !important;
  background-color: white;
  opacity: 1 !important;
  z-index: 1;
}

.ui.button.disabled.delete-button:hover,
.ui.button.disabled.delete-button:active,
ui.button.delete.disabled:hover,
.ui.button.delete.disabled:focus {
  color: rgb(254, 98, 91, 0.45) !important;
  box-shadow: 0 0 0 1px rgb(254, 98, 91, 0.45) inset !important;
  background-color: white;
}

.ui.menu .ui.dropdown .menu > .item.change-password-disabled,
.ui.menu .ui.dropdown .menu > .item.privacy-settings-disabled,
.ui.menu .ui.dropdown .menu > .item.create-mobile-disabled {
  color: rgba(0, 0, 0, 0.45) !important;
  white-space: normal;
}

.disable-social-buttons {
  pointer-events: none;
}

.logo-img-google-play {
  height: 48px;
  width: 164px;
  background-image: url(https://play.google.com/intl/en_us/badges/images/generic/en_badge_web_generic.png);
  background-size: 184px;
  background-repeat: no-repeat;
  background-position: -12px -12px;
}

.logo-img-app-store {
  height: 48px;
  width: 164px;
  background-image: url(https://developer.apple.com/app-store/marketing/guidelines/images/badge-download-on-the-app-store.svg);
  background-size: contain;
  background-repeat: no-repeat;
  background-position: 1px 0px;
}

.ui.button.delete {
  box-shadow: 0 0 0 1px #fe625b inset !important;
  color: #fe625b !important;
  background-color: inherit;
}

.ui.button.delete:hover,
.ui.button.delete:focus {
  box-shadow: 0 0 0 1px #db2828 inset !important;
  transition: color 0.2s, background-color 0.5s;
  color: #ffffff !important;
  background-color: #db2828;
}

a.item {
  color: #343a40;
  text-decoration: underline;
}

a.item:hover {
  color: #fe625b !important;
  text-decoration: underline;
}

.ui.link.list.list .active.item,
.ui.link.list.list .active.item a:not(.ui) {
  color: #fe625b !important;
  text-decoration: underline;
}

.ui.horizontal.divider {
  font-family: 'Muli', sans-serif;
}

.ui.link.list .item,
.ui.link.list .item a:not(.ui),
.ui.link.list a.item {
  color: #343a40;
}

.ui.secondary.pointing.menu .active.item {
  border-color: #212529;
  color: #212529 !important;
}

.anchor {
  padding: 0;
  margin: 0;
  display: inline;
  border: none;
  background-color: transparent;
  cursor: pointer;
  width: auto;
  height: auto;
  font-size: inherit;
  color: #fe625b;
}

.contribute-button {
  float: left;
  text-align: center;
  border-radius: 20px;
  border-style: none;
  /* color: black; */
  color: white;
  font-weight: 400;
  font-size: 1.2rem;
  /* background-color: #fdc439; */
  background-color: #fe625b;
  /* font-weight: 600; */
  /* background-color: #fdc439; */
  width: 120px;
  height: 40px;
  cursor: pointer;
  margin-left: 20px;
  width: 120px;
  height: 34px;
  padding-right: 10px;
}

.email-recovery-container {
  max-width: 400px;
  margin-left: auto;
  margin-right: auto;
  justify-content: center;
  padding-left: 10px;
  padding-right: 10px;
}

.email-recovery-container.disabled {
  opacity: 0.5;
  pointer-events: none;
}

.email-recovery-container label {
  text-align: left;
}

#circled-submit {
  border-radius: 69px !important;
  height: 37px;
  margin-top: 20px;
  width: 232px;
}

.message-sent-contact {
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 15px;
}

#cookiescript_rightpart {
  min-width: 10% !important;
}

table.cookiescript_fullreport {
  display: none !important;
}

#cookiescript_reportwrap {
  height: auto !important;
  overflow: auto;
}

#cookiescript_cookietablewrap {
  min-height: auto !important;
}

#cookiescript_aboutwrap {
  max-height: 92px !important;
}

#cookiescript_accept {
  background-color: #fe625b !important;
}

#cookiescript_manage:hover #cookiescript_manageicon .cookiescript_gear {
  fill: #fe625b !important;
}

#cookiescript_categories .cookiescript_active {
  background: #fe625b !important;
  color: #fefefe;
}

#cookiescript_maintabs .cookiescript_active {
  background: #fefefe;
  color: #fe625b !important;
}

#cookiescript_reportwrap::-webkit-scrollbar-thumb,
#cookiescript_aboutwrap::-webkit-scrollbar-thumb {
  background-color: #fe625b !important;
}

#cookiescript_badge {
  display: none !important;
}

#cookiescript_accept,
#cookiescript_save {
  background-color: #fe625b !important;
}

.show_cookie_settings #cookiescript_badge {
  display: flex !important;
}
