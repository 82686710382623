.upload-container .texture-list .ui.horizontal.list > .item,
.upload-container .artifact-list .ui.horizontal.list > .item {
  padding-top: 0px;
  margin: 0px;
}

.upload-container .artifact-list .ui.horizontal.list > .item .artifact,
.upload-container .texture-list .ui.horizontal.list > .item .texture {
  border: 3px solid transparent;
  position: relative;
  overflow: hidden;
  width: 70px;
  height: 70px;
}

.upload-container .artifact-list .ui.horizontal.list > .item .artifact-wrapper .display-case {
  width: 56px;
  height: 56px;
}

.upload-container .artifact-list .ui.horizontal.list > .item .artifact.disabled,
.upload-container .artifact-list .ui.horizontal.list > .item .artifact.disabled:hover,
.upload-container .texture-list .ui.horizontal.list > .item .texture.disabled,
.upload-container .texture-list .ui.horizontal.list > .item .texture.disabled:hover {
  opacity: 0.5;
  border: 3px solid transparent;
}

.upload-container .artifact-list .ui.horizontal.list > .item .artifact.selected,
.upload-container .texture-list .ui.horizontal.list > .item .texture.selected {
  border: 3px solid #009c9588;
}

.upload-container .artifact-list .ui.horizontal.list > .item .artifact:hover,
.upload-container .artifact-list .ui.horizontal.list > .item .artifact.selected:hover,
.upload-container .texture-list .ui.horizontal.list > .item .texture:hover,
.upload-container .texture-list .ui.horizontal.list > .item .texture.selected:hover {
  border: 3px solid #fd635b;
}

.upload-container .artifact-list .ui.horizontal.list > .item .artifact.placing,
.upload-container .texture-list .ui.horizontal.list > .item .texture.placing {
  border: 3px solid #fd635b;
}

.upload-container.ply,
.upload-container.flickr {
  margin-top: 0.5em;
}

.upload-container.poly .ui.horizontal.list > .item,
.upload-container.flickr .ui.horizontal.list > .item {
  display: inline-block;
  max-width: 20%;
}

.upload-container.poly .ui.horizontal.list > .item .artifact,
.upload-container.flickr .ui.horizontal.list > .item .artifact {
  width: auto;
  height: auto;
}

.upload-container.poly .ui.horizontal.list > .item img,
.upload-container.flickr .ui.horizontal.list > .item img {
  max-width: 100%;
}

.ui.form .field > label,
.ui.checkbox label {
  display: inline-block;
}
.label-hint {
  color: #fe615b;
  margin-left: 5px;
}
