.exhibition-list > .ui.vertical.segment {
  padding-right: 1rem;
}

.exhibition-filters,
.exhibition-search {
  max-width: 100%;
  min-width: 100%;
}

.content > .header h4.ui.header {
  font-size: 1rem;
  text-transform: uppercase;
  margin-bottom: 0.5em;
}

.ui.grid > .stretched.column:not(.row) > .exhibition-filters {
  -webkit-box-flex: 0;
  -webkit-flex-grow: 0;
  -ms-flex-positive: 0;
  flex-grow: 0;
}

.exhibition-search {
  padding: 0.5rem;
}

.layout-body .exhibition-search .ui.input {
  display: inline-block;
}

.layout-body .exhibition-search .ui.input input {
  width: 100%;
  background-color: rgba(255, 255, 255, 0.1);
}

.exhibition-list-grid {
  min-height: 8em;
}

.exhibition-list-grid .exhibition .content {
  margin-top: 0.5rem;
  margin-bottom: 0.2rem;
  text-align: left;
}

div.publication {
  opacity: 0.6;
}

div.views {
  opacity: 0.6;
}

.info-line {
  display: inline-flex;
}

/* .exhibition-list-grid .exhibition .content>.ui.text {
  display: inline-block;
} */

.exhibition-list-grid .exhibition .content .ui.text.views::after {
  content: '\00b7';
  line-height: 1rem;
  margin: 0 0.25em;
}

@media only screen and (max-width: 1380px) and (min-width: 992px),
  (max-width: 845px) and (min-width: 768px) {
  .info-line {
    display: block;
  }
  .exhibition-list-grid .exhibition .content .ui.text.views::after {
    content: '\00b7';
    line-height: 1rem;
    margin: 0 0.25em;
    display: none;
  }
}

.exhibition > .segment .exhibition-stats {
  display: none;
  padding: 0.05em 0.75em 0 0.15em;
  font-size: 1.5em;
  color: #fff;
}

.exhibition > .segment:hover .exhibition-stats {
  display: inline-block;
  padding: 0.4em 0.5em 0.4em 0.5em;
  font-size: 1.2em;
  color: #fff;
}

.exhibition .exhibition-aggregates {
  position: absolute;
  padding: 0.25em;
  top: 0.25em;
  left: 0.25em;
  background-color: transparent;
  pointer-events: none;
  border-radius: 0px;
}

.exhibition > .segment:hover > .exhibition .exhibition-aggregates {
  background-color: #212529;
  border: 0px solid;
  border-radius: 100px;
  opacity: 0.8;
}

.exhibition .exhibition-legacy {
  position: absolute;
  font-size: 1.3em;
}

.exhibition-legacy {
  padding: 0.3em 0.4em;
  bottom: 0.25em;
  right: 0.25em;
  color: #fff;
  border: 0px solid;
  border-radius: 100px;
  opacity: 0.8;
  font-size: 0.8em;
  vertical-align: middle;
  text-shadow: 0 0 1px #212529;
}

.exhibition > .segment:hover > .exhibition .exhibition-legacy {
  opacity: 1;
}

.star.icon {
  margin: 0;
}

.ui.segment {
  border-radius: 0;
}

.ui.publication.segment {
  padding: 0 1em 1em 1em;
}

.exhibition .image {
  margin: auto;
}

.exhibition-list-grid .exhibition .exhibition-controls {
  min-height: 2.5em;
}

.exhibition-list-grid .exhibition .exhibition-control-buttons {
  width: 100%;
  text-align: center;
  position: absolute;
  bottom: 0.75em;
  left: 0px;
  z-index: 1;
}

.exhibition-list-grid .exhibition .exhibition-control-buttons a {
  cursor: pointer;
}

.exhibition-list-grid .exhibition .exhibition-control-buttons button.control-remove {
  width: 40%;
  display: inline-block;
}

.control-edit {
  width: 40%;
  display: inline-block;
}

.exhibition-list-grid .exhibition > .image-container {
  position: relative;
  padding-bottom: 60%;
  width: 100%;
  height: 100%;
  display: block;
  overflow: hidden;
}

.exhibition-list-grid .exhibition > .image-container > a > .image {
  position: absolute;
  object-fit: cover;
  object-position: center center;
  min-width: 100%;
  min-height: 100%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  transition: min-width, min-height, top, left, transform, filter;
  transition-duration: 0.5s;
  filter: brightness(100%);
}

.exhibition-list-grid .exhibition {
  transition: background-color 0.5s;
  border-radius: 0px;
}

.exhibition-list-grid > .grid > .exhibition > .segment:hover {
  background-color: #eee;
}

.exhibition-list-grid > .grid > .exhibition > .segment .exhibition-controls .ui.button {
  opacity: 0;
}

.exhibition-list-grid > .grid > .exhibition > .segment:hover .exhibition-controls .ui.button {
  opacity: 1;
}

.exhibition-list {
  position: relative;
}

.ui.segment.exhibition-list-full {
  min-height: 600px;
  overflow: hidden;
  background-color: #fff;
}

.exhibition-list-full .exhibition.column {
  height: 100%;
  position: relative;
}

.exhibition-list-full .exhibition.column .exhibition {
  height: 100%;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.08);
}

.exhibition-list-full .exhibition.column .exhibition > a {
  text-align: center;
  display: block;
  width: 100%;
  height: 100%;
  vertical-align: middle;
  text-align: center;
}

.exhibition-list-full .exhibition.column .content {
  background-color: #000;
  color: #fff;
  padding: 0.5em;
  text-align: center;
  position: absolute;
  bottom: 0%;
  left: 0;
  width: 100%;
  /*transform: translate(0, -50%);*/
  height: 2.1em;
  opacity: 0.8;
}

.exhibition-list-full .selected .exhibition.column .content {
  height: auto;
  opacity: 1;
}

.exhibition-list-full .exhibition.column img.ui.medium.image {
  width: 100%;
  display: block;
  vertical-align: middle;
  position: absolute;
  top: 50%;
  transform: translate(0, -50%);
}

.ui.segment.exhibition-list-full .gallery-cell.reflection .content {
  z-index: -1;
}

.ui.button.large-button {
  display: table;
  text-align: center;
  width: auto;
  margin: auto;
  font-size: 1.25em;
  font-weight: bold !important;
  padding: 1.1em 2em 1.1em 2em !important;
  margin-top: 1.5em;
}

.ui.button.take-tour {
  margin-top: 0em;
}

.exhibition-list .ui.active.inverted.dimmer {
  background-color: rgba(247, 247, 247, 0.75);
}
