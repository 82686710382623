.chatbot .chatbot-text {
  min-height: 16em;
  max-height: 16em;
  overflow-y: auto;
  margin-bottom: 0.5em;
  border: 1px solid #eee;
  padding: 0.5em;
  margin-top: 1em;
}

.chatbot .chatbot-text .message .message-owner {
  font-weight: bold;
  padding-right: 0.5em;
}
