.exhibition-view {
  position: relative;
}

.exhibition-stepper {
  text-align: right;
  vertical-align: middle;
}
.exhibition-stepper .button {
  font-size: 0.9em;
}

.exhibition-frame {
  margin-top: 61px;
  /*
  transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  -webkit-transform: translate3d(0, 0, 0);
  position: relative;
  */
}

.exhibition-grid {
  display: table;
  width: 100%;
  vertical-align: top;
  position: relative;
}

.exhibition-grid .exhibition-column {
  display: table-cell;
  vertical-align: top;
}

.exhibition-grid .exhibition-sidebar-column {
  background-color: #ddd;
  /* position: fixed; */
  top: 4rem;
  line-height: 3rem;
  min-height: 3rem;
  width: 100%;
  z-index: 1;
  box-shadow: 3px 0px 3px rgba(0, 0, 0, 0.3);
}

.exhibition-grid .exhibition-sidebar .toolbar .toolbar-content-wrapper {
  position: static;
}

.exhibition-grid .exhibition-sidebar-column .ui.inverted.grey.menu {
  background-color: transparent;
  height: 3rem;
}

.exhibition-grid .exhibition-main-column {
  /* margin-top: 3rem; */
}

.exhibition-grid .exhibition-sidebar-column .ui.horizontal.menu {
  float: left;
  margin: 0;
  min-width: auto;
}

.exhibition-helper .artifact-list-wrapper,
.exhibition-helper .texture-list-wrapper {
  width: 100%;
}

.toolbar.top .exhibition-helper .ui.button,
.toolbar.bottom .exhibition-helper .ui.button,
.toolbar.top .exhibition-helper .icon.buttons,
.toolbar.bottom .exhibition-helper .icon.buttons {
  float: left;
  width: auto;
  line-height: 70px;
  vertical-align: middle;
  display: block;
}

.toolbar .exhibition-helper .ui.button {
  width: auto;
  line-height: 1em !important;
}

.exhibition-helper .ui.input {
  vertical-align: top;
  min-height: 2.2em;
  border-radius: 4px;
}

.exhibition-helper .ui.button {
  width: 100%;
  margin-bottom: 0.5em;
}

.exhibition-helper .upload-container .upload-wrapper {
  text-align: center;
}

.toolbar.top .upload-container .artifact-list,
.toolbar.bottom .upload-container .artifact-list,
.toolbar.top .upload-container .texture-list,
.toolbar.bottom .upload-container .texture-list {
  white-space: nowrap;
}

.exhibition-view .upload-container .artifact-list .artifacts,
.exhibition-view .upload-container .texture-list .textures {
  line-height: 1em;
  text-align: left;
}

.exhibition-view .construction-list {
  text-align: center;
}

.exhibition-view .construction-list > .item {
  width: 4em;
}

.exhibition-view .colours-list > .item {
  width: 12.5%;
  height: 2em;
}

.exhibition-view .colours-list > .item > .colour {
  padding: 0;
  display: block;
  border-width: 0;
  width: 100%;
  height: 100%;
}

.exhibition-view .construction-list > .item,
.exhibition-view .colours-list > .item,
.exhibition-view .construction-list > .item.inactive:hover,
.exhibition-view .colours-list > .item.inactive:hover {
  border: 3px solid transparent;
  display: inline-block;
}

.exhibition-view .construction-list > .item.inactive > img,
.exhibition-view .colours-list > .item.inactive > .colour {
  opacity: 0.5;
}

.exhibition-view .construction-list > .item:hover,
.exhibition-view .colours-list > .item:hover {
  border-color: #912d2c88;
}

.exhibition-view .construction-list > .item.selected,
.exhibition-view .colours-list > .item.selected {
  border-color: #912d2c;
}

.exhibition-view .template-list > .item,
.exhibition-view .template-list > .item.inactive:hover {
  border: 3px solid transparent;
  display: inline-block;
}

.exhibition-view .template-list > .item.inactive > img {
  opacity: 0.5;
}

.exhibition-view .template-list > .item:hover {
  border-color: #912d2c88;
}

.exhibition-view .template-list > .item.selected {
  border-color: #912d2c;
}

.exhibition-helper .ui.horizontal.list > .item .artifact .artifact-controls,
.exhibition-helper .ui.horizontal.list > .item .texture .texture-controls {
  text-align: center;
  position: absolute;
  top: 2px;
  right: 0px;
  width: 1.65em;
  display: none;
}

.exhibition-helper .ui.horizontal.list > .item .artifact .title,
.exhibition-helper .ui.horizontal.list > .item .texture .title {
  position: absolute;
  text-align: center;
  top: 0px;
  left: 0px;
  width: 100%;
  display: none;
}

.artifact-list .ui.horizontal.list > .item .artifact.placing .artifact-controls,
.exhibition-helper .ui.horizontal.list > .item:hover .artifact .artifact-controls,
.exhibition-view .texture-list .ui.horizontal.list > .item .texture.placing .texture-controls,
.exhibition-helper .ui.horizontal.list > .item:hover .texture .texture-controls {
  display: block;
}

.artifact-list .ui.horizontal.list > .item .artifact.placing .title,
.exhibition-helper .ui.horizontal.list > .item:hover .artifact .title,
.exhibition-view .texture-list .ui.horizontal.list > .item .texture.placing .title,
.exhibition-helper .ui.horizontal.list > .item:hover .texture .title {
  display: block;
}

.exhibition-helper .ui.horizontal.list > .item .artifact .artifact-controls .artifact-button,
.exhibition-helper .ui.horizontal.list > .item .texture .texture-controls .texture-button {
  border: none;
  background-color: rgba(0, 0, 0, 0.7);
  border-radius: 4px;
  margin: 1px;
  padding: 3px;
  font-size: 0.8em;
  display: block;
  cursor: pointer;
}

.exhibition-helper .ui.horizontal.list > .item .artifact .artifact-controls .artifact-button:hover,
.exhibition-helper
  .ui.horizontal.list
  > .item
  .artifact
  .artifact-controls
  .artifact-button.selected,
.exhibition-helper .ui.horizontal.list > .item .texture .texture-controls .texture-button:hover,
.exhibition-helper .ui.horizontal.list > .item .texture .texture-controls .texture-button.selected {
  background-color: rgba(0, 0, 0, 0.9);
}

.exhibition-helper .ui.horizontal.list > .item .artifact .artifact-controls .artifact-button i.icon,
.exhibition-helper .ui.horizontal.list > .item .texture .texture-controls .texture-button i.icon {
  margin: 0px;
  color: rgba(255, 255, 255, 0.9);
}

.exhibition-helper
  .ui.horizontal.list
  > .item
  .artifact
  .artifact-controls
  .artifact-button:hover
  i.icon,
.exhibition-helper
  .ui.horizontal.list
  > .item
  .artifact
  .artifact-controls
  .artifact-button.selected
  i.icon,
.exhibition-helper
  .ui.horizontal.list
  > .item
  .texture
  .texture-controls
  .texture-button:hover
  i.icon,
.exhibition-helper
  .ui.horizontal.list
  > .item
  .texture
  .texture-controls
  .texture-button.selected
  i.icon {
  color: #912d2c;
  filter: brightness(250%);
}

.exhibition-helper
  .ui.horizontal.list
  > .item
  .artifact
  .artifact-controls
  .artifact-button.disabled,
.exhibition-helper
  .ui.horizontal.list
  > .item
  .artifact
  .artifact-controls
  .artifact-button.disabled:hover,
.exhibition-helper .ui.horizontal.list > .item .texture .texture-controls .texture-button.disabled,
.exhibition-helper
  .ui.horizontal.list
  > .item
  .texture
  .texture-controls
  .texture-button.disabled:hover {
  background-color: rgba(0, 0, 0, 0.7);
}

.exhibition-helper
  .ui.horizontal.list
  > .item
  .artifact
  .artifact-controls
  .artifact-button.disabled
  i.icon,
.exhibition-helper
  .ui.horizontal.list
  > .item
  .texture
  .texture-controls
  .texture-button.disabled
  i.icon {
  color: rgba(255, 255, 255, 0.5);
  filter: brightness(100%);
}

.upload-container .item .image,
.upload-container .uploader {
  min-width: 16px;
  width: auto;
  vertical-align: top;
  text-align: center;
}

.upload-container .item .image {
  /* height: 64px; */
  /* max-width: 6rem; */
  background-color: rgba(0, 0, 0, 0.4);
  transform: translate(-50%, -50%);
  top: 50%;
  left: 50%;
  position: absolute;
  min-width: 100%;
  min-height: 100%;
  max-height: 120%;
  max-width: 180%;
}

.upload-container .item .artifact.display-case .image {
  height: 52px;
}

.upload-container .uploader.buttons,
.exhibition-view .toolbar-section .camera-controller .buttons {
  margin-top: 0px;
}

.upload-container .uploader .button {
  min-height: 100%;
  margin-right: 0px;
}

.exhibition-view .toolbar-section .camera-controller .buttons .icon:not(.button) {
  margin: 0px;
}

.exhibition-view .toolbar-section .buttons .right.pointing.label {
  margin-right: 0px;
}

.toolbar .overlay-dialog,
.toolbar .overlay-dialog .overlay-dialog-content form,
.toolbar .overlay-dialog .overlay-dialog-content h2 {
  text-align: left;
}

.dropzone,
.toolbar .overlay-dialog .dropzone {
  width: 100%;
  border: 2px dashed rgba(34, 36, 38, 0.15);
  color: rgba(0, 0, 0, 0.4);
  min-height: 4rem;
  margin-bottom: 1rem;
  text-align: center;
  padding: 0.5rem;
  cursor: pointer;
}

.exhibition-view .toolbar .ui.active.loading.dimmer:not(.inverted) {
  background-color: rgba(0, 0, 0, 0.3);
  z-index: 1;
}

.dropzone .image img {
  margin: auto;
  max-height: 128px;
}

.dropzone .image a {
  display: inline-block;
}

.dropzone .text {
  padding-bottom: 1rem;
}

.dropzone a {
  display: inline-block;
  margin: 0 0.25em;
  max-width: calc(100% - 1em);
  overflow: hidden;
}

.toolbar .dropzone {
  color: rgba(0, 0, 0, 0.5);
  border: 2px dashed rgba(0, 0, 0, 0.2);
}

.toolbar .dropzone .text {
  padding-bottom: 0.5rem;
}

.preview-container {
  position: relative;
  text-align: center;
}

.preview-container .ui.image img {
  margin: auto;
}

.menu .dropdown .license img {
  height: 1em;
  width: auto;
  display: inline-block;
  margin-right: 1em;
}

.overlay-dialog .artifact-info .pager {
  font-size: 0.8em;
}

.overlay-dialog .artifact-info .pager button {
  padding: 0.25em;
  margin: 0 !important;
}

.overlay-dialog .artifact-info .pager button > i {
  margin: 0 !important;
}

.overlay-dialog .artifact-info .pager .page {
  padding: 0 0.5em;
}

.overlay-dialog .artifact-info .obj-upload {
  text-align: center;
}

.overlay-dialog .artifact-info canvas.object-preview {
  width: 355px;
  max-width: 100%;
  height: 256px;
  margin: auto;
}

.overlay-dialog .artifact-info video.video-preview {
  width: 355px;
  max-width: 100%;
  margin: auto;
}

.overlay-dialog .artifact-info canvas.object-preview.rendered {
  cursor: pointer;
}

.overlay-dialog .texture-info .preview-wrapper,
.overlay-dialog .artifact-info .preview-wrapper {
  text-align: center;
}

.overlay-dialog .texture-info .preview-wrapper .ui.image,
.overlay-dialog .artifact-info .preview-wrapper .ui.image {
  text-align: center;
  height: auto;
  width: 100%;
}

.overlay-dialog .texture-info .preview-wrapper .ui.image img,
.overlay-dialog .artifact-info .preview-wrapper .ui.image img {
  max-height: 22rem;
  width: auto;
  height: auto;
}

.overlay-dialog .texture-info .preview-wrapper.multiple *,
.overlay-dialog .artifact-info .preview-wrapper.multiple * {
  display: inline-block;
}

.overlay-dialog .texture-info .preview-wrapper.multiple .ui.image,
.overlay-dialog .artifact-info .preview-wrapper.multiple .ui.image {
  width: auto;
}

.overlay-dialog .texture-info .preview-wrapper .ui.image a,
.overlay-dialog .artifact-info .preview-wrapper .ui.image a {
  display: inline-block;
}

.overlay-dialog .texture-info .preview-wrapper.multiple .ui.image img,
.overlay-dialog .artifact-info .preview-wrapper.multiple .ui.image img {
  max-height: 6em;
  max-width: 8em;
  margin-right: 0.5em;
  display: inline-block;
}

/* .exhibition-helper .exhibition-story-editor,
.artifact-list-wrapper,
.exhibition-helper .texture-list-wrapper {
  text-align: center;
} */

.toolbar.top .exhibition-helper .artifact-list-wrapper,
.toolbar.bottom .exhibition-helper .artifact-list-wrapper,
.toolbar.top .exhibition-helper .texture-list-wrapper,
.toolbar.bottom .exhibition-helper .texture-list-wrapper {
  text-align: left;
}

.toolbar.top .exhibition-helper .exhibition-story-editor,
.toolbar.bottom .exhibition-helper .exhibition-story-editor,
.toolbar.top .exhibition-helper .exhibition-story-editor .buttons,
.toolbar.bottom .exhibition-helper .exhibition-story-editor .buttons {
  text-align: left;
}

.exhibition-helper .exhibition-story-editor .buttons .ui.button {
  display: inline-block;
  float: none;
}

.exhibition-helper .storypoint {
  cursor: move;
  line-height: 60px;
  height: 60px;
  width: 66px;
  display: inline-block;
  vertical-align: middle;
  border-radius: 4px;
  position: relative;
  margin: 2px;
  filter: opacity(70%);
}

.exhibition-helper .storypoint.disabled {
  cursor: auto;
}

.exhibition-helper .storypoint.selected,
.exhibition-helper .storypoint.selected:hover {
  filter: opacity(100%);
}

.exhibition-helper .storypoint.drag-over {
  filter: opacity(100%) grayscale(100%);
}

.exhibition-helper .storypoint i.marker.icon {
  font-size: 1.5em;
  color: rgba(255, 255, 255, 0.2);
  vertical-align: center;
  display: inline-block;
  height: 100%;
  width: 100%;
  font-size: 4em;
}

.exhibition-helper .storypoint.drag i.marker.icon {
  opacity: 0.6;
  color: rgba(255, 255, 255, 0.8);
}

.exhibition-helper .storypoint .storypoint-editor {
  position: absolute;
  top: 1px;
  right: 0px;
  width: 1.7em;
  line-height: 1em;
  filter: opacity(100%);
  display: none;
}

.exhibition-helper .storypoint:hover .storypoint-editor,
.exhibition-helper .storypoint.selected .storypoint-editor {
  display: block;
}

.exhibition-helper .storypoint .storypoint-editor button {
  background-color: rgba(0, 0, 0, 0.5);
  color: #fff;
  cursor: pointer;
  text-align: center;
  border-radius: 4px;
  display: block;
  filter: opacity(60%);
  position: relative;
  padding: 3px;
  margin: 1px;
  font-size: 0.8em;
  border-width: 0;
}

.exhibition-helper .storypoint .storypoint-editor button:hover,
.exhibition-helper .storypoint .storypoint-editor button.selected {
  filter: opacity(100%);
}

.exhibition-helper .storypoint .storypoint-editor button i.icon {
  margin: 0;
  color: #fff;
}

.exhibition-helper .storypoint-list .ui.horizontal.list > .item {
  margin: 0;
  padding: 0;
}

.exhibition-info h5 {
  display: inline-block;
  margin: 0px 0.25em 0px 0px;
}

.exhibition-view.editor.grid {
  display: flex;
}

.exhibition-view.editor .exhibition-sidebar {
  width: 22rem;
  min-width: 22rem;
  position: relative;
}

.exhibition-sidebar.ui.menu.horizontal > .item {
  max-width: 20vw;
}

.exhibition-sidebar.ui.menu.horizontal > .item.disabled {
  pointer-events: none;
}

.exhibition-view.editor .exhibition-window {
  flex-grow: 1;
}

.curate-disabled {
  text-align: center;
  font-size: 2em;
  margin-top: 4em;
}

.exhibition-view.editor .toolbar .item {
  user-select: none;
}

.exhibition-view .texture-list > .textures > .item .image {
  /* max-width: 64px; */
  transform: translate(-50%, -50%);
  top: 50%;
  left: 50%;
  position: absolute;
  min-width: 100%;
  min-height: 100%;
  /* width: 100%; */
  max-height: 120%;
  max-width: 180%;
}

.medium img {
  max-width: 100%;
  max-height: 300px;
}

.text-selected {
  font-weight: 700 !important;
}

.streamable-tooltip-container {
  display: inline-block;
  position: relative;
  cursor: default;
}

.streamable-tooltip {
  margin-left: 6px;
  background-color: #f35d5d;
  border-radius: 100%;
  text-align: center;
  width: 18px;
  height: 18px;
  color: white;
  font-weight: 600;
}

.streamable-tooltip-text {
  visibility: hidden;
  background-color: #f35d5d;
  color: #fff;
  text-align: left;
  font-size: 0.98rem;
  font-weight: 700;
  padding: 5px 10px;
  border-radius: 6px;
  position: absolute;
  z-index: 100;
  opacity: 1;
  top: -513%;
  left: 194%;
  padding: 14px;
  display: block;
}

.streamable-tooltip-container:hover .streamable-tooltip-text {
  visibility: visible;
}

.autosave {
  margin-right: 30px;
}
.autosave label {
}
