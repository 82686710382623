.contact-form,
.contact-form div {
  background-color: #f3f3f3 !important;
}
.contact-form {
  padding: 15px;
  max-width: 1024px;
  margin: auto;
}
.contact-label {
  font-weight: 700;
  font-size: 18px;
}
.form-field {
  display: flex;
  flex-direction: column;
  padding: 20px 20px 0 20px;
  text-align: left;
}
.form-field div,
.form-field textarea {
  margin-top: 15px;
}
.form-field textarea {
  resize: none;
  border: 0;
  padding: 13px;
}
.form-field textarea:focus {
  outline: none;
}
.form-field:last:child {
  transform: scale(0.9);
}
.donate-now {
  list-style-type: none;
  margin: 25px 0 0 0;
  padding: 0;
  display: flex;
  text-align: center;
}

.donate-now li {
  float: left;
  margin: 0 5px 0 0;
  position: relative;
}
.donate-now li:first-child {
  flex-basis: 70%;
}
.donate-now li:last-child {
  flex-basis: 30%;
}

.donate-now input[type='radio'] {
  opacity: 0;
  position: absolute;
  left: 72%;
  bottom: 0;
}

.donate-now input[type='radio']:checked + label {
  background: #ffffff;
  -webkit-transition: color 1s ease;
  -moz-transition: opacity 2s ease-in;
  -o-transition: opacity 2s ease-in;
  color: black !important;
}
.donate-now input[type='radio']:checked + label::after {
  content: '\2713';
  padding-left: 10px;
}

.donate-now label {
  padding: 14px 0;
  font-size: 1.16rem;
  border: 1px solid transparent;
  display: block;
  border-radius: 8px;
  background-color: #f8f8f8;
  cursor: pointer;
  color: #c7c7c7 !important;
}

.donate-now label:hover {
  background: #ddd;
}
.contact-submit-button {
  margin: 26px auto 6px auto;
  background-color: #12ad8f;
  width: 92%;
  height: 38px;
  border-radius: 23px;
  opacity: 0.87;
  border: 1px solid transparent;
  cursor: pointer;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
}
.contact-submit-button:disabled,
.contact-submit-button[disabled] {
  background-color: #12ad8fb8;
}

.send-button {
  width: 20px;
  margin-left: 9px;
  filter: invert(1);
}
.contact-social-header {
  font-size: 1.68571429rem;
  margin-top: 44px;
}
.contact-column > * {
  flex-grow: unset !important;
}
.contact-column {
  padding: 1px !important;
}
.padded-contact {
  padding: 47px 24px 0 23px !important;
  text-align: left;
}
.contact-social-button button {
  width: 100%;
  padding: 11px 0;
  border: 0;
  background-color: #343a400d !important;
  text-align: left;
  padding-left: 9px;
}
.contact-social-button:last-child {
  margin-top: 8px;
}
.contact-form a {
  color: #343a40ad;
}
.contact-form a:hover {
  color: #343a40db;
}
.contact-social-button i {
  color: #343a40ad;
  margin-right: 6px;
}
.contact-wrapper {
  background-image: url('../styles/images/panorama-covers/cover1.jpg');
  width: 100%;
  position: relative;
  overflow: hidden;
  /* padding-bottom: 9rem; */
  height: 100%;
}
.contact-wrapper > div:last-child {
  width: 100%;
  height: 21vh;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
}
.contact-wrapper > .cover-column > .storeicons {
  justify-content: center;
}
.contact-wrapper > .cover-column > .storeicons > a:first-child {
  margin-right: 10px;
}
.contact-wrapper > .cover-column > .storeicons > a:last-child {
  margin-left: 10px;
}
@media (max-width: 450px) {
  .contact-wrapper > .cover-column > .storeicons > a:last-child,
  .contact-wrapper > .cover-column > .storeicons > a:first-child {
    margin: 3px 0;
  }
}
.contact-section {
  padding-bottom: 29px;
}
.contactus-header {
  text-align: center;
  color: black;
  padding: 51px 0;
  align-items: center;
  display: inline-flex;
  width: 100%;
}
.contactus-header h2 {
  width: 100%;
  margin: auto;
  display: inline-block;
  font-size: 2.8rem;
  line-height: 1.5;
}
.recap {
  transform: scale(0.9);
}
.verify-msg {
  color: #e27474;
  margin-top: 9px;
}
.contact-label::after {
  content: '*';
  color: #ea4949;
  padding-left: 5px;
}
.contact-input,
.contact-textarea {
  font-size: 1.3em;
}
.contact-input::placeholder {
  color: #757575 !important;
}
