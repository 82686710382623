.exhibition-view .exhibition-quality-settings {
  position: absolute;
  left: 3.6em;
  top: 7.2em;
  background-color: #fff;
  max-width: 52vw;
  z-index: 2;
  border-radius: 2%;
}

.quality-buttons-container {
  display: flex;
  justify-content: space-evenly;
  padding: 5px;
}

.quality-button {
  width: 5em;
  height: 2em;
  margin-left: 3px;
  margin-right: 3px;
  background-color: #fd635b;
  color: white;
  border: none;
  outline: none;
  border-radius: 10%;
}

.exhibition-quality-settings .quality-text {
  font-size: 1.2em;
  vertical-align: center;
  padding: 1px;
  margin-left: 2px;
  margin-right: 2px;
  text-align: center;
  -webkit-user-select: none; /* Safari */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* IE10+/Edge */
  user-select: none; /* Standard */
  min-width: 4em;
}

.exhibition-quality-settings h5 {
  margin: 0 !important;
}

.exhibition-quality-settings .quality-button:hover {
  background-color: #912d2c;
  color: white;
  -webkit-transition: background-color 0.5s;
  transition: background-color 0.5s;
}

.exhibition-quality-settings .section {
  border-bottom: 1px solid #ddd;
  text-align: center;
  margin: 1em;
  padding-bottom: 1em;
  font-size: 1.2em;
}

.exhibition-quality-settings .section:last-child {
  border-bottom: 0;
}

.exhibition-quality-settings h2 {
  padding: 0.5em;
  text-align: center;
  border-top-right-radius: 4px;
  border-top-left-radius: 4px;
  margin-bottom: 0;
  -webkit-user-select: none; /* Safari */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* IE10+/Edge */
  user-select: none; /* Standard */
}

.exhibition-quality-settings h2 .ui.button.close-button {
  position: absolute;
  top: 1em;
  right: 0.5em;
  padding: 0.5em;
}

.exhibition-quality-settings .disable-control {
  line-height: 1.5em;
  margin-top: 0.5em;
}

.exhibition-quality-settings .disable-control * {
  vertical-align: middle;
  margin-right: 0.5em;
}

.exhibition-quality-settings .paragraph .section .text {
  font-size: 1.2em;
  display: inline-block;
  width: calc(80% - 0.5em);
  vertical-align: top;
  -webkit-user-select: none; /* Safari */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* IE10+/Edge */
  user-select: none; /* Standard */
}

.exhibition-quality-settings .paragraph .section .image {
  height: auto;
  width: 20%;
  margin-left: 0.5em;
  display: inline-block;
}

.exhibition-quality-settings .paragraph .section:after {
  content: '';
  display: block;
  clear: both;
}

.exhibition-quality-settings .paragraph .section.noicon .text,
.exhibition-quality-settings .paragraph .section.noicon .text,
.exhibition-quality-settings .paragraph .section.keyboard .text,
.exhibition-quality-settings .paragraph .section.tour .text {
  width: 100%;
}

.exhibition-quality-settings .paragraph .section.keyboard .image,
.exhibition-quality-settings .paragraph .section.tour .image {
  width: 40%;
  display: block;
  margin: auto;
}

.exhibition-view.editor .exhibition-quality-settings .paragraph .section .image {
  width: 50%;
}

.exhibition-view.editor .exhibition-quality-settings .paragraph .section .text {
  width: calc(50% - 0.5em);
}

.exhibition-view.editor .exhibition-quality-settings .paragraph .section.noicon .text {
  width: 100%;
}
