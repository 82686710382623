.toolbar-overlay {
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  color: transparent;
  font-size: 24em;
  text-align: center;
  padding-top: 15%;
}

.toolbar {
  bottom: 0px;
  width: 100%;
  max-height: 90%;
  position: absolute;
  top: 1em;
  left: 1em;
  text-align: left;
}

.toolbar.right .toolbar-content,
.toolbar.left .toolbar-content,
.toolbar.float .toolbar-content {
  overflow-y: auto;
}

.toolbar.left {
  left: 0;
  right: auto;
  top: 0;
  min-height: 100%;
}

.toolbar.right {
  text-align: right;
  left: auto;
  right: 0;
  top: 0;
  min-height: 100%;
}

.toolbar.top {
  top: 0;
  bottom: auto;
  left: 0;
  width: 100%;
  min-height: 4em;
}

.toolbar.bottom {
  top: auto;
  bottom: 0;
  left: 0;
  width: 100%;
  min-height: 4em;
}

.toolbar.full {
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
}

.toolbar .toolbar-controls {
  text-align: right;
  padding: 0.25em;
  font-size: 0.8em;
  position: relative;
}

.toolbar-handle {
  width: 100%;
  height: 100%;
  z-index: 1;
  position: absolute;
  top: 0px;
  left: 0px;
  cursor: move;
  background-color: rgba(0, 0, 0, 0.3);
  height: 25px;
}

.toolbar .toolbar-controls a {
  background-color: rgba(0, 0, 0, 0.2);
  border-radius: 2px;
  margin: 2px;
  cursor: pointer;
  z-index: 2;
  position: relative;
}

.toolbar .toolbar-controls a i.icon {
  margin: 0;
}

.toolbar .toolbar-controls a:hover {
  background-color: rgba(0, 0, 0, 0.5);
}

.toolbar .toolbar-controls a.disabled,
.toolbar .toolbar-controls a.disabled:hover {
  background-color: rgba(0, 0, 0, 0.1);
}

.toolbar .toolbar-content {
  position: relative;
  padding: 0.5em;
}

.toolbar .toolbar-section {
  margin: 0 0 0.5em 0;
  background-color: rgba(0, 0, 0, 0.1);
  padding: 0.5em;
}

.toolbar .toolbar-section h4 {
  text-transform: uppercase;
  font-size: 1em;
}

.toolbar .toolbar-section:after {
  content: '';
  display: block;
  clear: both;
}

.toolbar.top .toolbar-section,
.toolbar.bottom .toolbar-section {
  min-width: 16em;
  float: left;
  margin-right: 0.5em;
  max-width: 32%;
  overflow: hidden;
}

.toolbar.top .toolbar-section.stackable,
.toolbar.bottom .toolbar-section.stackable {
  max-width: none;
  clear: both;
  float: none;
  text-align: left;
  margin-right: 0;
  overflow: visible;
}

.toolbar .ui.toggle.checkbox label:before {
  background-color: rgba(0, 0, 0, 0.45);
}

.toolbar .buttons {
  text-align: center;
  display: block;
}

.toolbar .toolbar-section .buttons {
  margin-top: 1rem;
}

.toolbar .toolbar-content-wrapper {
  position: relative;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  overflow: hidden;
  /*
  margin-top: -0.5em;
  padding-top: 0.5em;
  */
  z-index: 0;
}

.toolbar .help-text {
  text-align: center;
  font-size: 0.9em;
}

.toolbar .toolbar-content .toolbar-tabs {
  margin: 0 -0.5em;
  border-radius: 0 !important;
}

.toolbar .toolbar-content .ui.tabular.menu {
  border-color: rgba(0, 0, 0, 0.2);
  min-height: 2em;
  font-size: 0.845em;
}

.toolbar .toolbar-content .ui.tabular.menu .item {
  padding: 0 0.5em;
  cursor: pointer;
  margin-bottom: 0px;
  font-size: 1.07em;
}

.toolbar .toolbar-content .ui.tabular.menu .active.item {
  background-color: rgba(0, 0, 0, 0.05);
  color: #fe625b;
  font-weight: bold;
  border-color: transparent;
  border-radius: 0 !important;
}

.toolbar .toolbar-content .ui.tab.segment {
  background-color: transparent;
  padding: 0.5em 0.25em 0 0.25em;
  border: none;
  border-radius: 0;
  margin-bottom: 0;
}

.toolbar h4 .ui.mini.input {
  min-height: 1.8em;
  margin-left: 0.5em;
  max-width: 45%;
}

.toolbar h4 .ui.mini.input .icon {
  padding-left: 2em;
}
