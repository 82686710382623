.button-bar{
  display: flex;
}
.exhibition-outer-container,
.exhibition-info {
  position: relative;
}

.exhibition-info > .vertical.segments > .vertical > .horizontal.segments {
  margin-top: 0;
}

.exhibition-info .help-message {
  color: #888;
}

.shareButtons {
  background-color: white;
  border: none;
  margin-right: 1em;
}

.one.field.resolutions {
  width: 120px;
}

.exhibition-extras-buttons {
  display: inline-block;
}

.exhibition-view {
  position: relative;
}

.exhibition-view.viewer {
  height: calc(100vh - 12.5rem);
}

.exhibition-view.editor {
  height: calc(100vh - 7rem);
}

.fullscreen.fullscreen-enabled .exhibition-view.viewer,
.fullscreen.fullscreen-enabled .exhibition-view.editor,
.exhibition-view.viewer.embedded {
  height: 100vh;
}

.exhibition-extras-button {
  cursor: pointer;
  background-color: transparent;
  border: none;
  font-size: 1.4em;
  color: #343a40;
  height: 1em;
  height: 35px;
  width: 35px;
  border-radius: 50%;
  margin-left: 1em;
  background-color: #dfdfdf;
  box-shadow: 0 0 0 1px #dfdfdf inset;
}

.exhibition-extras-button:hover {
  color: #000000;
  height: 35px;
  width: 35px;
  box-shadow: 0 0 0 1px #dfdfdf inset;
  border-radius: 50%;
  background-color: #cccccc;
}

.renderframe {
  margin-top: 20px;
}

.embed-options {
  margin-top: 20px;
}

.exhibitionsharing * {
  display: inline-block;
}

.exhibitionsharing {
  text-align: right;
  display: inline-block;
  margin-right: 0.5em;
}

.exhibitionsharing .shareButtons {
  filter: opacity(90%);
  cursor: pointer;
}

.exhibitionsharing .shareButtons:hover {
  filter: none;
}

.exhibition-extras > .basic.segment {
  margin-bottom: 0px;
  padding-top: 0px;
}

.exhibition-likes,
.exhibition-views {
  display: inline-block;
  vertical-align: middle;
}

.exhibition-likes .ui.header,
.exhibition-views .ui.header {
  font-size: 1.2em;
}

.exhibition-likes .label,
.exhibition-views .label,
.exhibition-likes a,
.exhibition-views a {
  text-transform: lowercase;
  font-weight: normal;
  margin-right: 0.2em;
}

.exhibition-likes button .heart {
  font-size: 1.7em;
  margin-right: 0 !important;
}

.exhibition-likes button .empty.heart,
.exhibition-likes button.disabled .heart {
  filter: grayscale(100%);
}

.exhibition-embed .ui.form textarea {
  min-height: 4em;
  height: auto;
}

.exhibition-embed .ui.buttons button {
  margin-top: 0.5em;
}

.exhibition-user .ui.avatar,
.ui.avatar img {
  width: 3.5em;
  height: 3.5em;
  object-fit: cover;
}

.exhibition-outer-container h1 {
  /* line-height: 1rem; */
  font-size: 1.5em;
  margin: 0px;
}

.exhibition-user a {
  font-size: 1.2em;
  vertical-align: middle;
}

.exhibition-list-menu.ui.secondary.pointing.menu > .item {
  color: #767676;
}

.exhibition-list-menu .item {
  display: inline-block;
  margin-left: 50px;
  padding: 8px;
  cursor: pointer;
  font-size: 1.3em;
}

.exhibition-list-menu.ui.secondary.pointing.menu > .item.active {
  color: #1b1c1d;
}

.exhibition-list-page-menu-items {
  display: flex;
  width: 90%;
  margin: auto;
  flex-wrap: wrap;
}

.exhibition-list-page {
  min-height: 44rem;
}

.exhibition-list-page .ui.menu:not(.vertical) .item > .button {
  margin: 0px;
}

.exhibition-list-page .ui.secondary.pointing.menu .item.search {
  padding: 0px;
}

.exhibition-list-page .ui.secondary.pointing.menu {
  border-bottom: none;
  width: auto;
  margin-bottom: 1rem;
}

.exhibition-list-menu.ui.five.item.secondary .item {
  padding: 0 1rem 0 1rem !important;
  border: none;
  display: inline-block;
}
.exhibition-list-menu.ui.five.item.secondary .item > span {
  display: block;
  padding: 1rem 0 1rem 0;
  border-bottom: 2px solid transparent; /* rgba(34,36,38,.15); */
}

.exhibition-list-menu.ui.five.item.secondary .item.active > span {
  border-bottom: 2px solid #212529;
  color: #212529;
}

.exhibition-list-menu.ui.five.item.secondary.pointing.menu .item:last-child span {
  border-bottom: none;
}

.exhibition-list-menu .ui.input input {
  padding: 0.45em 1em;
}

.exhibition-list-page .exhibition-list-grid,
.exhibition-list-menu.ui.five.item.secondary.pointing.menu {
  margin-left: -1em !important;
  margin-right: -1em !important;
}

.exhibition-list-menu.ui.five.item.secondary.pointing.menu .item form {
  margin-top: 0.25em;
  margin-bottom: 0.25em;
}

.exhibition-list-menu.ui.five.item.secondary.pointing.menu .item form,
.exhibition-list-menu.ui.five.item.secondary.pointing.menu .item form * {
  display: inline-block;
  max-width: 100%;
}

/*
.exhibition-list-filter-wrapper {
  padding-top: 0.7em;
  padding-bottom: 0.02px;
  background-color: white;
}
*/

.exhibition-accessibility-view .view-storypoints {
  margin-top: 2em;
}

.exhibition-accessibility-view .view-storypoints > .item {
  margin-bottom: 1em;
  border-bottom: 1px solid #ddd;
  border-radius: 3px;
  padding-bottom: 1em;
  padding-top: 0.5em;
}

.exhibition-accessibility-view .view-storypoints > .item:last-child {
  border-bottom: none;
}

.exhibition-accessibility-view {
  min-height: 24em;
}

.exhibition-accessibility-view .view-artifacts .artifact-container {
  display: flex;
}

.exhibition-accessibility-view .view-artifacts .artifact-container .artifact-preview video {
  max-width: 100%;
}

.exhibition-accessibility-view .view-artifacts .artifact-container .artifact-content {
  max-width: 50%;
  margin-left: 1em;
}
