.fade {
  width: 200px;
  height: 200px;
  position: absolute;
  top: 400px;
  opacity: 0;
  z-index: 1;
  display: none;
}

.ghostButton {
  background: transparent;
  border: none !important;
  z-index: 10;
}

.linkButton :hover {
  color: red;
  z-index: 10;
}

.ghostButton :hover {
  color: red;
  z-index: 10;
}
.linkButton {
  text-decoration: underline;
  z-index: 10;
  color: blue;
}

.closeButton {
  position: absolute;
  right: 0px;
  text-decoration: underline;
  background: white;
  opacity: 0.5;
  border: none;
}

.animate-bottom {
  display: block;
  animation: animatebottom 1s ease-in forwards;
  -webkit-animation: animatebottom 1s ease-in forwards;
}
@-webkit-keyframes animatebottom {
  from {
    top: 400px;
    opacity: 0;
  }
  to {
    top: 0px;
    opacity: 1;
  }
}
@keyframes animatebottom {
  from {
    top: 400px;
    opacity: 0;
  }
  to {
    top: 0px;
    opacity: 1;
  }
}
