.cookies-buttons {
  text-align: center;
  margin: 0 auto;
  display: flex;
  justify-content: center;
}
.cookie-accept-button,
.cookie-decline-button {
  font-size: 1.1rem !important;
  background-color: inherit;
  color: white;
  border: 2px solid white !important;
  border-radius: 20px !important;
}
.cookie-accept-button:hover {
  background-color: #fe625b !important;
  color: #fff;
  border-color: #fe625b !important;
}
.cookie-decline-button:hover {
  background-color: #b5acac !important;
  color: #fff;
  border-color: #b5acac !important;
}
.cookies-wrapper {
  position: fixed;
  bottom: 0;
  padding: 0;
  right: 0;
  left: 0;
  display: flex !important;
  flex-direction: column;
  align-items: center !important;
  z-index: 999;
  background: rgba(61, 65, 66, 0.85);
}
.cookie-content {
  margin: 0 10px !important;
}
.CookieConsent {
  padding: 10px 0;
}

@media screen and (max-width: 768px) {
  .cookies-banner {
    margin: 16px auto 0 auto;
    max-width: 435px;
  }
}

#g-recaptcha div {
  margin: auto;
}
