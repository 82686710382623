.exhibition-view .exhibition-controls {
  padding: 0.25em;
}

.exhibition-view .exhibition-controls span {
  padding: 0.5em;
}

.exhibition-view .exhibition-controls button {
  cursor: pointer;
  font-size: 1.2em;
  line-height: 1.2em;
  display: inline-block;
  z-index: 1;
  padding: 0.5em;
  background-color: rgba(0, 0, 0, 0.5);
  border-radius: 4px;
  border-width: 0;
  color: rgba(255, 255, 255, 0.8);
  margin-left: 0.25em;
}

.exhibition-view .exhibition-controls.expanded button {
  background-color: rgba(255, 255, 255, 0.8);
  color: black;
}

.exhibition-view .exhibition-controls.expanded button:hover {
  background-color: #fff;
  color: black;
}

.exhibition-view .exhibition-controls button:first-child {
  margin-left: 0;
}

.exhibition-view .exhibition-controls button:hover {
  background-color: rgba(0, 0, 0, 0.6);
  color: rgba(255, 255, 255, 1);
}

.exhibition-view .exhibition-controls button.disabled,
.exhibition-view .exhibition-controls button.disabled:hover {
  background-color: rgba(0, 0, 0, 0.1);
  cursor: auto;
}

.exhibition-view .exhibition-controls i.icon {
  margin: 0px;
  display: inline-block;
}

.exhibition-view .exhibition-stories {
  position: absolute;
  bottom: 0px;
  left: 50%;
  transform: translate(-50%, 0);
}

.exhibition-view .exhibition-stories .exhibition-controls .storypoint-selector {
  padding-right: 0em;
  padding-left: 0em;
}

.exhibition-view .exhibition-stories .exhibition-controls .exhibition-storypoint-list {
  position: absolute;
  bottom: 3.25em;
  left: 6em;
  background-color: rgba(0, 0, 0, 1);
  border-radius: 2px;
  max-width: 48vw;
  overflow-y: auto;
  overflow-x: hidden;
  max-height: 48vh;
  padding: 0.5em;
}

.exhibition-view .exhibition-stories .exhibition-controls .exhibition-storypoint-list button {
  background-color: transparent;
  white-space: nowrap;
  padding: 0.2em;
  font-size: 1em;
}

.exhibition-view
  .exhibition-stories
  .exhibition-controls
  .exhibition-storypoint-list
  .selected
  button {
  color: rgba(255, 255, 255, 1);
}

.exhibition-view .exhibition-story-content {
  position: absolute;
  bottom: 3.5em;
  left: 50%;
  transform: translate(-50%, 0);
  border-radius: 4px;
  background-color: rgba(0, 0, 0, 0.6);
  width: 97%;
  max-width: 500px;
}

.exhibition-view .exhibition-story-text {
  color: #fff;
  font-size: 1em;
  padding: 1em 0.5em 1em 0.5em;
}

.exhibition-view .exhibition-story-text .exhibition-story-description {
  max-height: 7.5em;
  overflow-x: hidden;
  overflow-y: auto;
  text-align: justify;
}

.exhibition-view .exhibition-story-text .exhibition-story-description,
.exhibition-view .exhibition-story-text h3 {
  padding-right: 0.5em;
  padding-left: 0.5em;
}

.exhibition-view .exhibition-story-text h3 {
  color: #fff;
  font-size: 1.2em;
  margin-bottom: 0.5em;
  margin-right: 0.2em;
  text-align: left;
  width: 90%;
}

.exhibition-view .exhibition-story-content .ui.icon.button {
  position: absolute;
  top: 0.5em;
  right: 0.5em;
  padding: 0.5em;
  margin: 0;
}

.exhibition-view .exhibition-story-player .ui.icon.button.close-button {
  position: absolute;
  top: 0px;
  right: 0px;
  margin: -1em -1em 0 0;
  background-color: rgba(0, 0, 0, 0.2);
  padding: 0.35em;
  color: #db2828;
}

.exhibition-view .exhibition-story-text .buttons {
  margin-top: 1em;
  text-align: right;
}

.exhibition-info .edit-exhibition,
.exhibition-info .pick-exhibition {
  font-size: 0.6em;
  margin-left: 1em;
}

.artifact-viewer h3 {
  font-size: 1.2em;
  margin-bottom: 0.25em;
}

.artifact-viewer .artifact-image {
  text-align: center;
  position: relative;
}

.clicked-image {
  max-height: 256px;
}

.doubleclicked-image {
  max-height: 60vh;
  height: 60vh;
  max-width: 60vw;
}

.artifact-viewer .artifact-image .icon.button {
  position: absolute;
  top: 50%;
  margin-top: -1em;
  opacity: 0.5;
  padding: 0.5em;
}

.artifact-viewer .artifact-image .icon.button:hover {
  opacity: 0.8;
}

.artifact-viewer .artifact-image .icon.button.next {
  right: 0.5em;
}

.artifact-viewer .artifact-image .icon.button.previous {
  left: 0.5em;
}

.artifact-viewer audio {
  width: 100%;
  max-width: 100%;
}

.artifact-viewer .video-controls > button {
  float: left;
  border: none;
  color: #fff;
  background-color: #888888;
  border-radius: 3px;
  height: 2em;
  width: 2em;
  display: inline-block;
  margin-right: 0.5em;
}

.artifact-viewer .video-controls > .input-range {
  margin: 0.25em 0.5em 0 6em;
}

.artifact-viewer .video-controls {
  margin: 0.5em 0;
  padding: 0.5em;
  background-color: #dddddd;
  border-radius: 3px;
  height: 3em;
}

.artifact-viewer .video-controls:after {
  clear: both;
}

.exhibition-view.viewer .overlay-dialog .overlay-dialog-content {
  margin-top: 0.25em;
  margin-left: 0.25em;
  border-radius: 4px;
  max-width: 24vw;
}

.exhibition-view.viewer .overlay-dialog .overlay-dialog-content {
  min-height: 10em;
}

.exhibition-view.viewer .overlay-dialog .overlay-dialog-content .artifact-info {
  max-height: 48vh;
  overflow-y: auto;
  overflow-x: hidden;
}

.exhibition-view .exhibition-audio-player .exhibition-controls .input-range {
  border-bottom-right-radius: 4px;
  border-bottom-left-radius: 4px;
}

.exhibition-view .exhibition-audio-player .exhibition-controls.expanded button {
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}
