.login-form {
  top: 0px;
  bottom: 0px;
  margin-top: 2px;
  margin-bottom: 2px;
  box-shadow: none;
}

.ui.facebook.button.login-register {
  background-image: url('./images/social-17.png');
  background-color: transparent;
  box-shadow: none;
}

.ui.facebook.button.login-register:hover {
  background-image: url('./images/social-20.png');
  background-color: transparent;
  box-shadow: none;
}

.ui.twitter.button.login-register {
  background-image: url('./images/social-19.png');
  background-color: transparent;
  box-shadow: none;
  color: none;
}

.ui.twitter.button.login-register:hover {
  background-image: url('./images/social-22.png');
  background-color: transparent;
  box-shadow: none;
}

.ui.google.button.login-register {
  background-image: url('./images/social-18.png');
  background-color: transparent;
  box-shadow: none;
}

.ui.google.button.login-register:hover {
  background-image: url('./images/social-21.png');
  background-color: transparent;
  box-shadow: none;
}

.layout.full .layout-body .ui.button.login-register {
  width: 60px;
  height: 55px;
  display: inline-block;
  background-position: center center;
  background-size: 60px 55px;
  background-repeat: no-repeat;
  margin: 0px;
}

.ui.fluid.button.facebook {
  background-color: white;
}

.ui.fluid.button.twitter {
  background-color: white;
}

.ui.fluid.button.google {
  background-color: white;
}

.logo {
  background-image: url('./images/logo_small.png');
  background-color: transparent;
  background-position: center top;
  background-repeat: no-repeat;
  background-size: auto 100%;
  height: 80px;
  display: block;
  margin-top: 8px;
  flex-grow: 0 !important;
}

label {
  font-family: 'Muli', sans-serif;
}

.password-form .ui.form {
  text-align: left;
}

.password-form {
  padding-bottom: 3em;
}

.password-form .ui.segment.terms {
  text-align: center;
  padding-top: 3em;
  margin-bottom: -0.5em;
}

@media only screen and (max-width: 767px) {
  .logo {
    height: 64px;
  }
}

.previewText {
  width: 100%;
  margin-top: 20px;
}

.fileInput {
  width: 100%;
  height: 100%;
}

.submitButton {
  width: 100%;
  height: 100%;
}

.profile-dropzone {
  width: 14em;
  height: 14em;
  border: 2px dashed rgba(34, 36, 38, 0.15);
  color: rgba(0, 0, 0, 0.4);
  min-height: 4rem;
  margin: 0 auto 1rem auto;
  text-align: center;
  padding: 1rem;
  line-height: 12em;
  cursor: pointer;
  border-radius: 100%;
  text-align: center;
  vertical-align: middle;
}

.profile-dropzone img.ui.image {
  max-height: 100%;
  display: inline-block;
}

.profile-info .ui.segment.vertical {
  padding: 0 0 3em 0;
}

.ui.segment.social-buttons {
  padding-top: 0px;
}

.reset-password-box .message {
  text-align: center;
}

.profile-view .profile-header {
  padding-top: 0;
}

.profile-view .profile-header img {
  margin: auto;
}

.profile-view .profile-body .ui.vertical.segment {
  border-bottom: none;
}

.profile-container .edit-profile {
  margin: 1em auto 0 auto;
  display: block;
}

.profile-container .ui.three.item.secondary.pointing.menu.stackable {
  cursor: pointer;
  margin-top: 1em;
}

.profile-container .tab-body .dimmer {
  min-height: 8em;
}

.profile-container {
  background-color: #ffffff;
  min-height: 44rem;
}

.profile-container .user-profile {
  background-color: #ededed;
  padding: 50px 28px 50px 28px;
  margin-left: 10px;
}

@media only screen and (max-width: 991px) {
  .profile-container .user-profile {
    margin-right: 10px;
  }
}

.profile-container .ui.secondary.pointing.menu.stackable {
  border-bottom: none;
  background-color: #ffffff;
  margin-left: 1em;
  cursor: pointer;
}

.ui.celled.grid > .column:not(.row),
.ui.celled.grid > .row > .column {
  box-shadow: none;
}

.profile-container .ui.celled.grid > .column:not(.row),
.ui.celled.grid > .row > .column {
  padding: 1em 0 1em 1em;
}

span.stats {
  display: inline-block;
  padding: 0 0.75em 0 0.75em;
}

.country {
  display: block;
  margin-bottom: 14px;
}

span.black-numbers {
  color: black;
  font-weight: 700;
}
.follow-button.ui.teal.basic.compact.button,
.following-button.ui.teal.compact.button,
.follow-button.ui.teal.compact.button {
  border: 2px;
  border-radius: 30px;
  margin-top: 1em;
  width: 90px;
}

.following-button-section,
.follow-button-section {
  text-align: center;
  margin-top: 20px;
}

.round-image {
  width: 150px !important;
  height: 150px !important;
  object-fit: cover;
  border-radius: 50% !important;
}
