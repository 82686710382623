.messenger {
  position: fixed;
  top: 8rem;
  right: 1em;
  z-index: 1301;
  width: 32em;
  max-width: calc(100vw - 3em);
}

.messenger .message-wrapper {
  box-shadow: 0 2px 10px 0 #767676;
  margin-bottom: 1em;
  border-radius: 4px;
}

.messenger .message > .close.icon {
  cursor: pointer;
}

.messenger .message > .header {
  padding-right: 1em;
}

@keyframes fade {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}

.messenger .message-wrapper {
  animation-delay: 3s;
  animation-name: fade;
  animation-duration: 1s;
  animation-fill-mode: forwards;
}
