.ui.dimmer.modals {
  z-index: 1100;
}

.ui.dimmer.modals .modal {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  margin: auto;
}

.actions > .buttons.center.aligned {
  width: 100%;
  display: flex;
  justify-content: space-evenly;
}

.overlay-dialog {
  position: absolute;
  top: 0%;
  left: 0%;
  width: 100%;
  height: 100%;
  background-color: #00000080;
  z-index: 1200;
}

.overlay-dialog.fixed {
  position: fixed;
}

.overlay-dialog.clear {
  pointer-events: none;
  background-color: transparent;
}

.overlay-dialog .overlay-dialog-content {
  color: #000;
  background-color: #fff;
  padding: 1em;
  width: 1024px;
  max-width: 75%;
  pointer-events: auto;
  position: relative;
}

.overlay-dialog .overlay-dialog-content > div {
  max-height: calc(100vh - 7em);
  overflow-y: auto;
  overflow-x: hidden;
}

.overlay-dialog .overlay-dialog-content.left {
  margin: 2em 0 0 2em;
}

.overlay-dialog .overlay-dialog-content.right {
  margin: 2em 0 0 auto;
}

.overlay-dialog .overlay-dialog-content.center {
  margin: 2em auto 0 auto;
}

.overlay-dialog .overlay-dialog-content.transparent {
  background-color: rgba(255, 255, 255, 0.75);
  border: none;
}

.overlay-dialog .overlay-dialog-content.fixed {
  margin-top: 4.5em;
}

.overlay-dialog .dialog-buttons {
  margin-top: 1em;
  text-align: right;
}

.centered {
  text-align: center;
}

.overlay-dialog .ui.tabular.menu > .item {
  cursor: pointer;
}

.overlay-dialog .overlay-dialog-content .ui.icon.button.close-button {
  position: absolute;
  top: 0px;
  right: 0px;
  margin: 0.5em 0.5em 0 0;
  padding: 0.5em;
  border: none;
}

.overlay-dialog .overlay-dialog-content .ui.segment.vertical.form-buttons {
  float: right;
}

.overlay-dialog .overlay-dialog-content .ui.segment.vertical:last-child {
  padding-bottom: 0;
}

.overlay-dialog .overlay-dialog-content .ui label,
.overlay-dialog .overlay-dialog-content .ui.toggle.checkbox input:checked ~ .box,
.overlay-dialog .overlay-dialog-content .ui.toggle.checkbox input:checked ~ label {
  color: #000 !important;
}

.overlay-dialog .overlay-dialog-content .ui.input,
.overlay-dialog .overlay-dialog-content .ui.input input,
.overlay-dialog .overlay-dialog-content .ui.input textarea {
  color: #000;
}

.overlay-dialog-content.not-verified-notification {
  width: 512px;
  top: 30%;
  /* bring your own prefixes */
  transform: translateY(-30%);
  padding-left: 40px;
  padding-right: 40px;
}

.not-verified-notification__buttons-container {
  display: flex;
  justify-content: flex-end;
}
